import React from 'react';
import { Box } from '../Rebass';
import Text from '../Text';
const configMap = {
    match: {
        bg: 'rgba(211, 234, 220, 0.5)',
        headingColor: 'rgba(53, 101, 74, 1)',
        contentColor: 'rgba(53, 101, 74, 0.7)',
    },
    bestMatch: {
        bg: 'rgba(198,231,232,1)',
        headingColor: 'rgba(34,105,109,1)',
        contentColor: 'rgba(34,105,109,0.7)',
    },
    otherMatch: {
        bg: 'rgba(250, 199, 164, 0.5)',
        headingColor: 'rgba(126, 74, 33, 1)',
        contentColor: 'rgba(126, 74, 33, 0.7)',
    },
    noMatch: {
        bg: 'rgba(246,189,201,0.25)',
        headingColor: 'rgba(155, 53, 77, 1)',
        contentColor: 'rgba(155, 53, 77, 0.7)',
    },
};
const ResultLodgeNotification = ({ variant = 'match', availableTitle, availableDescription, sx, }) => {
    const { bg, headingColor, contentColor } = configMap[variant];
    return (React.createElement(Box, { sx: sx },
        React.createElement(Box, { bg: bg, sx: { p: '24px' } },
            React.createElement(Text, { size: "body", fontWeight: "bold", color: headingColor }, availableTitle),
            React.createElement(Box, { marginTop: "5px" },
                React.createElement(Text, { size: "body", color: contentColor }, availableDescription)))));
};
ResultLodgeNotification.defaultProps = {
    availableTitle: null,
    availableDescription: null,
};
export default ResultLodgeNotification;
