import { css } from '@emotion/react';
import theme from '../../theme';
export const cardWrapperStyle = css `
  &:hover .card-headline {
    border-color: ${theme.colors.textDark};
  }
`;
export const cardHeadlineStyle = ({ isLink }) => css `
  position: relative;
  display: inline;
  ${isLink
    ? `
        border-bottom: 1px solid ${theme.colors.transparent};
        transition: border-color ${theme.speed.slow} ease;
        `
    : ''}
`;
