import React from 'react';
import { Box, Flex } from '../Rebass';
import Text from '../Text';
import Heading from '../Heading';
import Meta from '../Meta';
import styled from '@emotion/styled';
import { cardHeadlineStyle, cardWrapperStyle } from './common';
const CardWrapper = styled(Flex)(cardWrapperStyle);
const StyledHeading = styled(Heading)(cardHeadlineStyle);
const CardStrip = ({ headline, description, renderCta, meta = [], size = 'standard', bg, padding, colorScheme, imagePosition = 'left', imageSx = {}, renderImage, isLink, }) => {
    const isStandard = size === 'standard';
    const isImageLeft = imagePosition === 'left';
    const { width: imageWidth, height: imageHeight, ...restSx } = imageSx;
    return (React.createElement(CardWrapper, Object.assign({ flexDirection: ['column', 'column', 'row'], height: "100%", alignItems: ['flex-start', 'flex-start', 'stretch'], bg: bg }, padding),
        renderImage && (React.createElement(Box, { mr: isImageLeft ? [0, 0, 4, 6] : [0], ml: isImageLeft ? [0] : [0, 0, 4, 6], height: imageHeight ? imageHeight : ['200px', '200px', 'auto'], width: imageWidth ? imageWidth : ['100%', '100%', '200px'], sx: {
                flex: '0 0 auto',
                order: [1, 1, isImageLeft ? 1 : 2],
                ...imageSx,
            } }, renderImage())),
        React.createElement(Box, { py: [3], pr: [1, 1, 4], pl: isImageLeft ? [1, 1, 0] : [1, 1, 4], sx: {
                flex: '1 1 auto',
                order: [2, 2, isImageLeft ? 2 : 1],
            } },
            Boolean(meta.length) && (React.createElement(Box, { mb: [1] },
                React.createElement(Meta, { meta: meta, size: "tiny" }))),
            React.createElement(Box, { mb: [description ? 2 : 0] },
                React.createElement(StyledHeading, { className: "card-headline", isLink: isLink, size: isStandard ? 'h5' : 'h4' }, headline)),
            React.createElement(Box, { mb: [renderCta ? 3 : 0] },
                React.createElement(Text, { size: "small" }, description)),
            renderCta && renderCta(colorScheme))));
};
CardStrip.defaultProps = {
    headline: '',
    description: '',
    meta: [],
    image: null,
    size: 'standard',
    lodge: null,
    bg: 'transparent',
    padding: {},
    colorScheme: 'brandBrown',
};
export default CardStrip;
