import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '../Rebass';
import Button from '../Button';
import Text from '../Text';
import Icon from '../Icon';
import Pattern, { LAYOUT_TYPES } from '../Pattern';
import { motion, AnimatePresence } from '../Motion';
import { wrap } from 'popmotion';
import useWindowWidth from '../../hooks/useWindowWidth';
import useHasMounted from '../../hooks/useHasMounted';
const StyledGallery = styled(Box) `
  position: relative;
  text-align: center;
`;
const AnimatedContainer = styled(motion.div) `
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    user-drag: none;
  }
`;
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;
const GalleryCarousel = ({ items, arrowPosition, hasPagination, lodge, renderCta, children, showPattern, ratio = '66.7188%', overflow = 'hidden', patternProps, initialIndex = 0, ...props }) => {
    const [[index, direction], setIndex] = useState([initialIndex, 0]);
    const windowWidth = useWindowWidth();
    const hasMounted = useHasMounted();
    if (!hasMounted) {
        return null;
    }
    const childrenLength = React.Children.count(children);
    const imageIndex = wrap(0, childrenLength, index);
    const paginate = (newDirection) => setIndex([index + newDirection, newDirection]);
    const variants = {
        enter: (direction) => {
            return {
                x: direction > 0 ? windowWidth : -windowWidth,
            };
        },
        center: {
            zIndex: 1,
            x: 0,
        },
        exit: (direction) => {
            return {
                zIndex: 0,
                x: direction < 0 ? windowWidth : -windowWidth,
            };
        },
    };
    return (React.createElement(StyledGallery, Object.assign({}, props, { sx: { maxWidth: '1250px', marginX: 'auto' } }),
        showPattern && (React.createElement(Pattern, Object.assign({ layoutType: LAYOUT_TYPES.GALLERY }, patternProps))),
        React.createElement(Box, { sx: {
                position: 'relative',
                paddingBottom: ratio,
                overflowX: 'hidden',
                overflow: overflow,
            } },
            React.createElement(AnimatePresence, { initial: false, custom: direction },
                React.createElement(AnimatedContainer, { key: index, variants: variants, custom: direction, animate: "center", initial: "enter", exit: "exit", transition: {
                        x: {
                            type: 'spring',
                            damping: 15,
                            stiffness: 50,
                        },
                    }, drag: "x", dragConstraints: { left: 0, right: 0 }, dragElastic: 1, onDragEnd: (e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);
                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        }
                        else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    } }, React.Children.toArray(children)[imageIndex])),
            React.createElement("div", { style: { display: 'none' } }, children),
            childrenLength > 1 && (React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "icon", colorScheme: "baseWhite", icon: "chevron-left", onClick: () => paginate(-1), sx: {
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: [3, 8],
                        zIndex: 5,
                    } }),
                React.createElement(Button, { variant: "icon", colorScheme: "baseWhite", icon: "chevron-right", onClick: () => paginate(1), sx: {
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: [3, 8],
                        zIndex: 5,
                    } })))),
        hasPagination && (React.createElement(Flex, { justifyContent: "space-between", px: [4, 10], mt: [1] },
            React.createElement(Flex, { alignItems: "center" },
                React.createElement(Text, { fontFamily: "serif", size: "tiny", sx: { display: ['none', 'block'] } },
                    imageIndex + 1,
                    "/",
                    childrenLength),
                items[imageIndex] && items[imageIndex].desc && (React.createElement(React.Fragment, null,
                    React.createElement(Box, { mx: [2], sx: { display: ['none', 'block'] } },
                        React.createElement(Icon, { name: "dot", size: 7 })),
                    React.createElement(Text, { fontFamily: "serif", size: "tiny" }, items[imageIndex].desc)))),
            renderCta && React.createElement(Box, { sx: { display: ['block'] } }, renderCta())))));
};
export default GalleryCarousel;
