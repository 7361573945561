import React from 'react';
import { Flex, Box } from '../Rebass';
import Heading from '../Heading';
import Text from '../Text';
import Meta from '../Meta';
import styled from '@emotion/styled';
import { cardHeadlineStyle, cardWrapperStyle } from './common';
const CardWrapper = styled(Flex)(cardWrapperStyle);
const StyledHeading = styled(Heading)(cardHeadlineStyle);
const CardFeatured = ({ headline, description, meta, image, bg, padding, highlight, isLink, }) => {
    return (React.createElement(CardWrapper, Object.assign({ flexDirection: ['column', 'column', 'row'], bg: bg }, padding),
        React.createElement(Box, { mr: [8], width: [1, 1, 0.6], flex: "1 0 auto", sx: { position: 'relative' } },
            highlight && (React.createElement(Flex, { sx: {
                    position: 'absolute',
                    top: '12px',
                    left: '12px',
                    bg: 'white',
                    zIndex: 1,
                    borderRadius: '4px',
                    padding: '4px 5px',
                    maxWidth: '50%',
                } },
                React.createElement(Text, { size: "extraSmall", sx: { textTransform: 'uppercase', overflowWrap: 'break-word' } }, highlight))),
            image),
        React.createElement(Box, { pt: [3] },
            Boolean(meta.length) && (React.createElement(Box, { mb: [2] },
                React.createElement(Meta, { meta: meta, size: "small" }))),
            React.createElement(Box, { mb: [3] },
                React.createElement(StyledHeading, { className: "card-headline", isLink: isLink, size: "h2" }, headline)),
            React.createElement(Box, null,
                React.createElement(Text, { size: "body", color: "textDark", lineHeight: "1.5" }, description)))));
};
CardFeatured.defaultProps = {
    headline: '',
    highlight: '',
    description: '',
    meta: [],
    image: null,
    bg: 'transparent',
    padding: {},
};
export default CardFeatured;
