import React from 'react';
import { Box } from '../Rebass';
import { Container } from '../Container';
import styled from '@emotion/styled';
import theme from '../../theme';
const BorderContainer = styled(Box) ``;
const StyledBorder = styled.hr `
  height: 0px;
  margin: 0px;
  padding: 0;
  border-style: solid;
  border-width: 0;
  border-bottom: 1px solid
    ${({ borderColor }) => theme.colors[borderColor]};
`;
const Border = ({ sx, borderColor = 'borderDark', isContainerised = true, ...props }) => {
    let children = isContainerised ? (React.createElement(Container, null,
        React.createElement(StyledBorder, { borderColor: borderColor }))) : (React.createElement(StyledBorder, { borderColor: borderColor }));
    return (React.createElement(BorderContainer, Object.assign({ sx: sx }, props), children));
};
export default Border;
