import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '../Rebass';
import Button from '../Button';
import Text from '../Text';
import Icon from '../Icon';
import Pattern, { LAYOUT_TYPES } from '../Pattern';
import { motion, useAnimation } from '../Motion';
import { wrap } from 'popmotion';
import useHasMounted from '../../hooks/useHasMounted';
import useMeasure from '../../hooks/useMeasure';
const StyledGallery = styled(Box) `
  position: relative;
  text-align: center;
  overflow-x: hidden;
`;
const ItemsContainer = styled(motion.div) `
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
const AnimatedContainer = styled(motion.div) `
  width: 100%;
  flex: 0 0 100%;
  margin-right: 150px;

  img {
    user-drag: none;
  }
`;
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;
const GalleryCarousel = forwardRef(({ items, arrowPosition, hasPagination, lodge, renderCta, children, showPattern, overflow = 'hidden', patternProps, initialIndex = 0, ...props }, parentRef) => {
    const [containerHeight, setContainerHeight] = useState('auto');
    const [[index, direction], setIndex] = useState([initialIndex, 0]);
    const hasMounted = useHasMounted();
    const { ref, width } = useMeasure();
    const controls = useAnimation();
    const hasPaged = useRef(false);
    const childrenLength = React.Children.count(children);
    const imageIndex = wrap(0, childrenLength, index);
    const paginate = (newDirection) => {
        setIndex([index + newDirection, newDirection]);
        hasPaged.current = true;
    };
    const updateHeight = (height) => {
        if (height) {
            setContainerHeight(height);
        }
    };
    useEffect(() => {
        const val = { x: -(width + 150) * imageIndex };
        if (!hasPaged.current) {
            controls.set(val);
        }
        else {
            controls.start(val);
        }
    }, [imageIndex, width, hasPaged.current]);
    useImperativeHandle(parentRef, () => ({ updateHeight }));
    if (!hasMounted) {
        return null;
    }
    return (React.createElement(Box, { sx: { position: 'relative' } },
        React.createElement(StyledGallery, Object.assign({}, props, { sx: {
                maxWidth: '1250px',
                marginX: 'auto',
                height: ['auto', 'auto', 'auto', containerHeight],
                overflowY: 'hidden',
            }, ref: ref }),
            showPattern && (React.createElement(Pattern, Object.assign({ layoutType: LAYOUT_TYPES.GALLERY }, patternProps))),
            React.createElement(ItemsContainer, { initial: false, animate: controls, transition: {
                    x: {
                        type: 'spring',
                        damping: 15,
                        stiffness: 50,
                    },
                } }, React.Children.map(children, (child, childIndex) => (React.createElement(Slide, { index: childIndex, currentIndex: imageIndex, paginate: paginate, updateHeight: updateHeight }, child))))),
        hasPagination && (React.createElement(Flex, { justifyContent: "space-between", px: [4, 10], mt: [1] },
            React.createElement(Flex, { alignItems: "center" },
                React.createElement(Text, { fontFamily: "serif", size: "tiny", sx: { display: ['none', 'block'] } },
                    imageIndex + 1,
                    "/",
                    childrenLength),
                items[imageIndex] && items[imageIndex].desc && (React.createElement(React.Fragment, null,
                    React.createElement(Box, { mx: [2], sx: { display: ['none', 'block'] } },
                        React.createElement(Icon, { name: "dot", size: 7 })),
                    React.createElement(Text, { fontFamily: "serif", size: "tiny" }, items[imageIndex].desc)))),
            renderCta && React.createElement(Box, { sx: { display: ['block'] } }, renderCta()))),
        React.createElement(Button, { variant: "icon", colorScheme: "baseWhite", size: "small", icon: "chevron-left", onClick: () => paginate(-1), sx: {
                position: 'absolute',
                top: ['20%', '20%', '20%', '50%'],
                transform: 'translateY(-50%)',
                left: ['-20px'],
                zIndex: 5,
            } }),
        React.createElement(Button, { variant: "icon", colorScheme: "baseWhite", size: "small", icon: "chevron-right", onClick: () => paginate(1), sx: {
                position: 'absolute',
                top: ['20%', '20%', '20%', '50%'],
                transform: 'translateY(-50%)',
                right: ['-20px'],
                zIndex: 5,
            } })));
});
const Slide = (props) => {
    const ref = useRef();
    const { index, currentIndex, children, paginate, updateHeight } = props;
    useEffect(() => {
        if (index === currentIndex && ref.current) {
            updateHeight(ref.current.getBoundingClientRect().height);
        }
    }, [currentIndex, ref.current]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (index === currentIndex && ref.current) {
                updateHeight(ref.current.getBoundingClientRect().height);
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [ref]);
    return (React.createElement(AnimatedContainer, { key: index, ref: ref, className: index === currentIndex ? 'slide active' : 'slide', drag: "x", dragConstraints: { left: 0, right: 0 }, dragElastic: 1, onDragEnd: (e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
            }
            else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
            }
        } }, children));
};
export default GalleryCarousel;
