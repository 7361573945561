import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Box } from '../Rebass';
export const LAYOUT_TYPES = {
    HERO: 'hero',
    BANNER: 'banner',
    CARDHERO_TOP: 'top',
    CARDHERO_BOTTOM: 'bottom',
    GALLERY: 'gallery',
};
export const PATTERN_POSITIONS = {
    TOP: 'top',
    BOTTOM: 'bottom',
};
const stylesByLayoutType = (props) => {
    switch (props.layoutType) {
        case LAYOUT_TYPES.HERO:
            return css `
        right: ${theme.space[20]}px;
        bottom: -${theme.space[13]}px;
      `;
        case LAYOUT_TYPES.CARDHERO_TOP:
            return css `
        left: ${theme.space[21]}px;
        top: -${theme.space[13]}px;
      `;
        case LAYOUT_TYPES.CARDHERO_BOTTOM:
            return css `
        right: ${theme.space[21]}px;
        bottom: -${theme.space[13]}px;
      `;
        case LAYOUT_TYPES.GALLERY:
            return css `
        right: ${theme.space[20]}px;
        top: -${theme.space[13]}px;
      `;
        case LAYOUT_TYPES.BANNER:
        default:
            return css `
        top: -${theme.space[5]}px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.3;
      `;
    }
};
const StyledSVGWrapper = styled(Box) `
  opacity: 1;
  position: absolute;
  z-index: ${theme.zIndex.pattern};
  width: ${theme.space[20]}px;
  height: ${theme.space[20]}px;
  ${stylesByLayoutType};
`;
const StyledSVG = styled.svg `
  opacity: 0.9;
`;
const Pattern = ({ layoutType, path, color, size }) => {
    return (React.createElement(StyledSVGWrapper, { layoutType: layoutType },
        React.createElement(StyledSVG, { style: { height: `${size}px`, width: `${size}px` }, viewBox: `0 0 152 152` },
            React.createElement("path", { d: path, fill: color }))));
};
Pattern.defaultProps = {
    layoutType: LAYOUT_TYPES.BANNER,
    path: theme.pattern,
    color: theme.colors.brandBrown,
    size: 152,
};
export default Pattern;
