import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Box, Flex } from '../Rebass';
import Heading from '../Heading';
import Text from '../Text';
import theme from '../../theme';
import Pattern, { LAYOUT_TYPES } from '../Pattern';
import { motion } from '../Motion';
import useHeroParallax from '../../hooks/useHeroParallax';
const StyledHero = styled(Flex) `
  width: 100%;
  height: 100vh;
  position: relative;
`;
const ImageContainer = styled(Box) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const HeroImage = motion.custom(styled(Box) `
  width: 100%;
  height: 100%;
`);
const AdditionalContent = styled(Box) `
  position: absolute;
  display: grid;
  display: -ms-grid;
  grid-template-rows: 50px;
  -ms-grid-rows: 50px;
  // prettier-ignore
  -ms-grid-columns: (33%)[3];
  left: 0;
  bottom: 0;
  width: 100%;

  @media (max-width: ${theme.breakpoints[1]}) {
    justify-items: center;
  }
`;
export const ProgressContainer = styled(Box) `
  position: absolute;
  right: ${theme.space[5]}px;
  bottom: 0;
`;
const animate = keyframes `
  0% {
      top: -50%;
  }
  100% {
      top: 100%;
  }
  0% {
      top: -50%;
  }
}`;
export const ProgressLine = styled(Box) `
  width: 1px;
  position: relative;
  height: 100px;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-left: 1px solid ${theme.colors.baseWhite};
  }

  &:after {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    border-left: 1px solid
      ${({ color }) => (color ? color : theme.colors.brandBrown)};
    animation: ${animate} 1.75s ease-in infinite;
  }
`;
const Headline = {
    Container: styled(Box) `
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `,
    Inner: styled(Box) `
    position: absolute;
    max-width: 80%;
    @media (max-width: ${theme.breakpoints[3]}) {
      min-width: 90%;
    }
    ${(props) => props.heroType === 'SPVC'
        ? `bottom: 10%;
          left: 10%;
          @media (min-width: ${theme.breakpoints[1]}) {
            max-width: 40%;
          }`
        : `top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);`}
  `,
};
const Cell = styled(Box) `
  -ms-grid-column: ${({ column }) => column};
  -ms-grid-row: 1;
`;
const Caption = motion.custom(Box);
const DescriptionContainer = styled(Box) `
  position: absolute;
  max-width: 80%;
  ${(props) => props.heroType === 'SPVC'
    ? `bottom: 2%;
        left: 10%;
        @media (min-width: ${theme.breakpoints[1]}) {
          max-width: 40%;
        }`
    : `top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);`}
`;
const Hero = forwardRef(({ headline, description, caption, color, image, showPattern, patternProps, renderButton, colorScheme, captionSx, headlineSx, heroType, ...rest }, ref) => {
    const { headlineStyle, backgroundStyle, captionStyle } = useHeroParallax();
    return (React.createElement(Box, null,
        React.createElement(StyledHero, Object.assign({ ref: ref, justifyContent: "center", alignItems: "center" }, rest),
            React.createElement(ImageContainer, null,
                React.createElement(HeroImage, { role: "img", title: caption || headline || null, 
                    // @ts-ignore
                    style: backgroundStyle }, image)),
            headline && (React.createElement(Headline.Container, null,
                React.createElement(Headline.Inner, { heroType: heroType },
                    React.createElement(motion.div, { style: {
                            ...headlineStyle,
                            willChange: 'transform, opacity, letter-spacing',
                        } },
                        React.createElement(Heading, { as: "h1", size: "hero", color: color, sx: { ...headlineSx } }, headline))))),
            description && (React.createElement(DescriptionContainer, { heroType: heroType },
                React.createElement(motion.div, { style: {
                        ...headlineStyle,
                        willChange: 'transform, opacity, letter-spacing',
                    } },
                    React.createElement(Heading, { size: "h3", color: color, sx: headlineSx }, description)))),
            React.createElement(AdditionalContent, { sx: { gridTemplateColumns: ['100%', '20% 60% 20%'] }, p: [2] },
                renderButton && (React.createElement(Cell, { column: 1, sx: { display: ['none', 'flex'] } },
                    React.createElement(Flex, { justifyContent: "flex-start", alignItems: "flex-end" }, renderButton && renderButton(color)))),
                caption && (React.createElement(Cell, { sx: { display: 'flex' }, justifyContent: "center", alignItems: "flex-end", textAlign: "center", column: 2 },
                    React.createElement(Caption, { sx: { maxWidth: ['70%', 'initial'] }, style: captionStyle },
                        React.createElement(Text, { size: "caption", color: color, textAlign: "center", fontFamily: 'variable', sx: { ...captionSx } }, caption))))),
            React.createElement(ProgressContainer, null,
                React.createElement(ProgressLine, { color: colorScheme })),
            showPattern && (React.createElement(Pattern, Object.assign({ layoutType: LAYOUT_TYPES.HERO }, patternProps))))));
});
Hero.defaultProps = {
    headline: '',
    caption: '',
    color: theme.colors.textWhite,
    lodge: '',
    image: null,
    renderButton: (_) => { },
};
export default Hero;
