import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import theme from '../../theme';
import Icon from '../Icon';
import { motion, AnimatePresence, AnimateSharedLayout } from '../Motion';
import { Box } from '../Rebass';
const pane = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 85% 85%)`,
        transition: {
            type: 'spring',
            stiffness: 20,
            restDelta: 2,
        },
    }),
    closed: {
        clipPath: 'circle(0px at 85% 85%)',
        transition: {
            type: 'spring',
            stiffness: 400,
            damping: 40,
        },
    },
};
const StyledButton = styled(Box) `
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  bottom: 15%;
  right: 15%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  z-index: ${({ isOpen }) => isOpen ? theme.zIndex.navbar + 1 : theme.zIndex.notification};
  transform: translate(50%, 55%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.bgLight};
`;
const Exclamation = styled(Box) `
  border-radius: 50%;
  background: ${theme.colors.statusError};
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: ${({ isOpen }) => isOpen ? theme.zIndex.navbar + 1 : theme.zIndex.notification};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const Nav = motion.custom(styled(Box) `
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ isOpen }) => isOpen ? theme.zIndex.navbar + 1 : theme.zIndex.notification};
`);
const StyledNavigation = motion.custom(styled(Box) `
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: ${({ isOpen }) => (isOpen ? 'auto' : 'hidden')};
`);
const StyledIcon = styled(Box) `
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;
const Background = motion.custom(styled(Box) `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: ${theme.colors.bgLight};
`);
const Item = motion.custom(Box);
const MenuItem = (props) => {
    const variants = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 50, damping: 15 },
            },
        },
        closed: {
            y: 50,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 },
            },
        },
    };
    return (React.createElement(Item, { layout: true, pb: [2], variants: variants }, props.children));
};
const Navigation = (props) => {
    const { isOpen, children, toggle } = props;
    const variants = {
        open: {
            transition: { staggerChildren: 0.2, delayChildren: 0.2 },
        },
        closed: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 },
        },
    };
    return (React.createElement(AnimateSharedLayout, null,
        React.createElement(StyledNavigation, { isOpen: isOpen, p: [4], pt: [9], variants: variants },
            isOpen && (React.createElement(StyledIcon, { onClick: toggle },
                React.createElement(Icon, { name: "close", size: 24 }))),
            React.Children.map(children, (child, index) => (React.createElement(MenuItem, { i: index, key: `notification-item-${index}` }, child))))));
};
const MenuToggle = ({ isOpen, toggle }) => (React.createElement(StyledButton, { isOpen: isOpen, onClick: toggle },
    React.createElement(Box, null,
        React.createElement(Icon, { name: "notification", size: 23 })),
    React.createElement(Exclamation, null)));
const NotificationPane = (props) => {
    const { isOpen, toggleOpen } = props;
    const [height, setHeight] = useState(0);
    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef && containerRef.current) {
            setHeight(containerRef.current.offsetHeight);
        }
    }, []);
    const hasAlerts = React.Children.count(props.children) > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(AnimatePresence, { initial: false }, isOpen && hasAlerts && (React.createElement(Nav, { isOpen: isOpen, ref: containerRef, custom: height, initial: "closed", animate: isOpen ? 'open' : 'closed', exit: "closed" },
            React.createElement(Background, { variants: pane }),
            React.createElement(Navigation, { isOpen: isOpen, toggle: toggleOpen }, props.children)))),
        !isOpen && hasAlerts ? (React.createElement(MenuToggle, { isOpen: isOpen, toggle: toggleOpen })) : null));
};
export default NotificationPane;
