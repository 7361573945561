import React from 'react';
import { Box, Flex, Image } from '../Rebass';
import Icon from '../Icon';
import theme from '../../theme';
import styled from '@emotion/styled';
const ListItemContainer = styled(Flex)(({ isLink, hasBorder }) => `
    cursor: ${isLink ? 'pointer' : 'default'};
    border: 0;
    border-bottom: ${hasBorder ? '1px' : '0'};
    border-style: solid;
    border-color: ${theme.colors.borderDark};

    ${isLink
    ? `&:hover .list-item-headline {
      border-color: ${theme.colors.textDark};
    }`
    : ''}
  `);
const HeadlineContainer = styled(Box) `
  transition: border-color ${theme.speed.slow} ease;
  border-bottom: 1px solid ${theme.colors.transparent};
`;
const ListItem = ({ icon, image, isLink, hasBorder, iconRight, iconLeft, iconRightSize = 16, iconLeftSize = 32, isSpaced, children, iconLeftColor, iconRightColor, ...props }) => (React.createElement(ListItemContainer, Object.assign({ justifyContent: isSpaced ? ['space-between'] : ['flex-start'], alignItems: "center", isLink: isLink, hasBorder: hasBorder }, props),
    image && React.createElement(Image, { src: image }),
    iconLeft && (React.createElement(Box, { css: { lineHeight: 1 }, mr: [1] },
        React.createElement(Icon, { name: iconLeft, size: iconLeftSize, duoColor: iconLeftColor }))),
    React.createElement(HeadlineContainer, { className: "list-item-headline" }, children),
    iconRight && (React.createElement(Box, { css: { lineHeight: 1 }, ml: [1] },
        React.createElement(Icon, { name: iconRight, size: iconRightSize, sx: { color: iconRightColor || 'textBlack' } })))));
ListItem.defaultProps = {
    icon: null,
    image: null,
    isLink: false,
    hasBorder: false,
    isIconRight: false,
    isSpaced: false,
};
export default ListItem;
