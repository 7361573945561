import React from 'react';
import { Box, Flex } from '../Rebass';
import Text from '../Text';
import styled from '@emotion/styled';
import theme from '../../theme';
const StyledAuthor = styled(Flex) `
  border-top: 1px solid ${theme.colors.baseGrayVeryLight};
`;
const AuthorSimple = ({ name, role, image }) => {
    return (React.createElement(StyledAuthor, { alignItems: "center", pt: [3], mt: [3] },
        image && React.createElement(Box, { mr: [2] }, image),
        React.createElement(Flex, { flexDirection: "column", flex: "2 1 auto" },
            React.createElement(Box, { mb: [1] },
                React.createElement(Text, { size: "small", fontWeight: "bold" },
                    "By ",
                    name)),
            React.createElement(Box, null,
                React.createElement(Text, { size: "small" }, role)))));
};
export default AuthorSimple;
