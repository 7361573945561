import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Flex, Box } from '../Rebass';
import Text from '../Text';
import theme from '../../theme';
import { resolveColor } from '../../utils/helpers';
import useClickOutside from '../../hooks/useClickOutside';
const StyledButton = styled(Button)(({ hoverBg, hoverText, border }) => {
    return `
  cursor: pointer;
  border-radius: 5px;
  transition: background ${theme.speed.default} ease;
  ${border ? `border: ${border}` : ''};

  :hover {
    background-color: ${hoverBg};

    & .button-text, .icon {
      color: ${hoverText};
    }
  }
`;
});
const StyledText = styled(Text) `
  transition: color ${theme.speed.default} ease;
  white-space: nowrap;
`;
const StyledIcon = styled.i(({ color, order }) => `
  order: ${order};
  color: ${color};
  transition: color ${theme.speed.default} ease;
  margin: ${order === 0 ? `0 ${theme.space[1]}px 0 0` : `0 0 0 ${theme.space[1]}px`};
  font-size: ${theme.fontSizes.extraSmall};
  display: flex;
  align-items: center;
`);
const sizeMap = {
    default: {
        padding: {
            px: [2, 4],
            py: ['11px'],
        },
        text: 'small',
    },
    small: {
        padding: {
            px: [2],
            py: [1],
        },
        text: 'tiny',
    },
};
function getButtonColors(color, variant) {
    switch (variant) {
        case 'primary':
            return {
                bg: color,
                border: `1px solid ${color}`,
                // TODO: this is an edge case with the white button, need a more elegant way to solve
                color: color === theme.colors.textWhite
                    ? theme.colors.textDark
                    : theme.colors.textWhite,
                hoverBg: theme.colors.transparent,
                hoverText: color,
            };
        case 'outline':
            return {
                bg: theme.colors.transparent,
                border: `1px solid ${color}`,
                color,
                hoverBg: color,
                // TODO: this is an edge case with the white button, need a more elegant way to solve
                hoverText: color === theme.colors.baseWhite
                    ? theme.colors.textDark
                    : theme.colors.textWhite,
            };
        case 'simple':
            return {
                bg: theme.colors.transparent,
                color,
                hoverBg: color,
                hoverText: theme.colors.textWhite,
            };
    }
}
const ButtonStandard = ({ size = 'default', children, variant = 'primary', icon, iconPosition, onClick = () => { }, renderPopover, popoverPosition = 'bottom', popoverPositionHorizontal = 'left', colorScheme = 'brandBrown', ...rest }) => {
    const [isClicked, setClicked] = useState(false);
    const sizeProps = sizeMap[size];
    const colors = getButtonColors(resolveColor(colorScheme), variant);
    const [iconOrder, textOrder] = iconPosition === 'left' ? [0, 1] : [1, 0];
    const wrapperRef = useClickOutside(() => setClicked(false));
    return (React.createElement(Box, { ref: wrapperRef, sx: { position: 'relative' } },
        React.createElement(StyledButton, Object.assign({ variant: variant, onClick: () => {
                onClick();
                setClicked(!isClicked);
            } }, sizeProps.padding, colors, rest),
            React.createElement(Flex, null,
                React.createElement(Box, { sx: { order: textOrder } },
                    React.createElement(StyledText, { className: "button-text", size: sizeProps.text, color: colors.color }, children)),
                icon && (React.createElement(StyledIcon, { "data-testid": "button-icon", order: iconOrder, color: colors.color, className: `icon icon-${icon}` })))),
        renderPopover && (React.createElement(Box, { sx: {
                display: isClicked ? 'block' : 'none',
                position: 'absolute',
                ...(popoverPositionHorizontal === 'left'
                    ? { left: 0 }
                    : { right: 0 }),
                ...(popoverPosition === 'bottom'
                    ? { top: '100%', paddingTop: '5px' }
                    : { bottom: '100%', paddingBottom: '5px' }),
            } }, renderPopover(colorScheme)))));
};
export default ButtonStandard;
