import React from 'react';
import { Box } from '../Rebass';
import styled from '@emotion/styled';
import { Meta, Heading, Text } from '../../';
import theme from '../../theme';
const StyledCard = styled(Box) `
  border: 1px solid ${theme.colors.baseGrayLight};
  vertical-align: top;
`;
const CardText = ({ headline, subtitle, description, children, meta, ...props }) => (React.createElement(StyledCard, Object.assign({ px: [4], py: [5] }, props),
    headline && (React.createElement(Heading, { size: "h4", fontWeight: 'normal', mb: [1] }, headline)),
    subtitle && (React.createElement(Box, { mb: [3] },
        React.createElement(Text, { size: "small" }, subtitle))),
    description && (React.createElement(Box, { mb: [8] },
        React.createElement(Text, { size: "body", sx: { whiteSpace: 'pre-line' } }, description))),
    meta && React.createElement(Meta, { meta: meta }),
    children && React.createElement(Box, { mt: [3] }, children)));
export default CardText;
