import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '../Rebass';
import Heading from '../Heading';
import Icon from '../Icon';
import theme, { breakpoints } from '../../theme';
const StyledMenuLink = styled(Flex) `
  cursor: pointer;

  & .icon {
    opacity: 1;
    transition: all ${theme.speed.default} ease-in;
    transform: translateX(0px);
  }

  @media (min-width: ${breakpoints.sm}px) {
    & .icon {
      opacity: 0;
    }
    :hover {
      & .icon {
        opacity: 1;
        transform: translateX(10px);
      }
    }
  }
`;
const MenuLink = ({ children, color, size = 'menu', link, sx, onClick, ...rest }) => (React.createElement(StyledMenuLink, Object.assign({ className: "menu-link", alignItems: "center", sx: sx, onClick: onClick, justifyContent: ['space-between', 'flex-start'] }, rest),
    React.createElement(Heading, { size: size, color: color, fontWeight: "light" }, children),
    React.createElement(Icon, { name: "chevron-right", size: 16, sx: { color: theme.colors[color] } })));
export default MenuLink;
