import { useLayoutEffect, useRef, useState } from 'react';
const useMeasure = () => {
    const [{ height, width }, setDims] = useState({ height: 0, width: 0 });
    const ref = useRef();
    useLayoutEffect(() => {
        if (ref.current) {
            const dims = ref.current.getBoundingClientRect();
            setDims({ height: dims.height, width: dims.width });
        }
    }, [ref, ref.current]);
    return { ref, height, width };
};
export default useMeasure;
