import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '../Rebass';
import Button from '../Button';
import theme from '../../theme';
import { AnimatePresence, motion } from '../Motion';
const StyledGallery = styled(Box) `
  position: relative;
  text-align: center;
  /* min-height: 100vh; */
  width: 100%;
  overflow: hidden;
`;
const ButtonsWrapper = styled(Flex) `
  position: absolute;
`;
const animation = {
    enter: {
        y: 0,
        opacity: 1,
    },
    initial: {
        opacity: 0,
        y: 100,
    },
    exit: {
        opacity: 0,
        y: -100,
    },
};
const GalleryBanner = ({ lodge, delay = 6000, children, bg = 'brandBrown', ...props }) => {
    const [index, setIndex] = useState(0);
    useEffect(() => {
        const next = (index + 1) % React.Children.count(children);
        const timeout = setTimeout(() => setIndex(next), delay);
        return () => clearTimeout(timeout);
    }, [index, delay]);
    return (React.createElement(StyledGallery, Object.assign({ justifyContent: "center", alignItems: "center" }, props, { bg: bg, color: theme.colors.baseWhite }),
        React.createElement(AnimatePresence, { exitBeforeEnter: true },
            React.createElement(motion.div, { key: `banner-${index}`, variants: animation, animate: "enter", exit: "exit", initial: "initial", transition: {
                    type: 'spring',
                    damping: 15,
                    stiffness: 50,
                } }, React.Children.toArray(children)[index])),
        React.Children.count(children) > 1 && (React.createElement(ButtonsWrapper, { sx: {
                right: ['50%', '100px'],
                transform: ['translate(50%, 0)', 'translate(0, -50%)'],
                top: ['90%', '50%'],
            }, flexDirection: ['row', 'column'] }, React.Children.map(children, (_, i) => (React.createElement(Button, { variant: "icon", icon: "dot", size: "default", colorScheme: "transparent", hasBorder: true, my: [0, 1], mx: [1, 0], sx: {
                opacity: i === index ? theme.opacity.full : theme.opacity.feint,
                ':hover': {
                    opacity: theme.opacity.full,
                },
            }, onClick: () => setIndex(i), key: i })))))));
};
export default GalleryBanner;
