import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '../Rebass';
import Heading from '../Heading';
import { GridContainer as Grid } from '../Container';
import { motion } from '../Motion';
import { useInView } from 'react-intersection-observer';
const StyledBox = styled(Box) `
  max-width: 1280px;
  margin: auto;
`;
const listVariants = {
    animate: {
        transition: {
            staggerChildren: 0.1,
        },
    },
    initial: {},
};
const listItemVariants = {
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            stiffness: 50,
            damping: 15,
        },
    },
    initial: {
        x: -20,
        opacity: 0,
    },
};
const AnimatedFlex = motion.custom(Flex);
const AnimatedGrid = motion.custom(Grid);
const AnimatedBox = motion.custom(styled(Box) `
  -ms-grid-column: ${({ index, cols }) => (index % cols) + 1};
  -ms-grid-row: ${({ index, cols }) => Math.floor(index / cols) + 1};
`);
const List = ({ headline, children, cols, renderDescription, sx, shouldAnimate = true, }) => {
    const length = React.Children.count(children);
    const numRows = Math.ceil(length / cols);
    const { ref, inView } = useInView({ threshold: 0.2 });
    const animate = shouldAnimate ? inView : !shouldAnimate;
    return (React.createElement(StyledBox, { ref: ref, sx: sx, className: "List" },
        headline && (React.createElement(Box, { mb: [4] },
            React.createElement(Heading, { size: "h2", fontWeight: 'normal' }, headline))),
        renderDescription && React.createElement(Box, { mb: [4] }, renderDescription),
        cols ? (React.createElement(AnimatedGrid, { sx: {
                gridTemplateColumns: ['1fr', '1fr', `repeat(${cols}, 1fr)`],
                columnGap: [2],
                gridTemplateRows: ['auto', 'auto', `repeat(${numRows}, 1fr)`],
                gridAutoFlow: ['row', 'row', 'column'],
            }, variants: listVariants, animate: animate ? 'animate' : 'initial', initial: "initial" }, React.Children.map(children, (child, index) => (React.createElement(AnimatedBox, { index: index, cols: cols, variants: listItemVariants }, child))))) : (React.createElement(Box, null,
            React.createElement(AnimatedFlex, { width: "100%", flexWrap: "wrap", variants: listVariants, animate: animate ? 'animate' : 'initial', initial: "initial" }, React.Children.map(children, (child) => (React.createElement(AnimatedBox, { mr: [3], variants: listItemVariants }, child))))))));
};
export default List;
