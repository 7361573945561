import React from 'react';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Box } from '../Rebass';
const StyledContent = styled(Box) `
  font-family: ${theme.fonts.sans};
  font-weight: ${theme.fontWeights.normal};
  img {
    width: inherit;
    max-width: 100%;
    height: auto;
  }
  p {
    margin-bottom: ${theme.space[2]}px;
    font-family: ${theme.fonts.sans};
    font-weight: ${theme.fontWeights.light};
    a {
      word-break: break-word;
    }
  }
  a {
    color: inherit;
    text-decoration: underline !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  p b,
  p strong {
    font-weight: ${theme.fontWeights.normal};
  }
  h1 b,
  h2 b,
  h3 b,
  h4 b,
  h5 b,
  h6 b,
  h1 strong,
  h2 strong,
  h3 strong,
  h4 strong,
  h5 strong,
  h6 strong {
    font-weight: ${theme.fontWeights.headingNormal};
    font-family: ${theme.fonts.serif};
  }
  h5 b,
  h6 b,
  h5 strong,
  h6 strong {
    font-weight: ${theme.fontWeights.headingBold};
  }
  em,
  i {
    font-style: italic;
    &.icon {
      font-style: normal;
    }
  }
  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-left: 20px;
    margin-bottom: ${theme.space[4]}px;
    font-family: ${theme.fonts.sans};
    font-weight: ${theme.fontWeights.light};
  }

  ol li {
    list-style-type: decimal;
  }
  ul li {
    list-style-type: disc;
  }
  small {
    font-size: ${theme.fontSizes.small};
  }
  hr {
    height: 0px;
    margin: 0px;
    padding: 0;
    border-style: solid;
    border-width: 0;
    border-bottom: 1px solid ${theme.colors.borderDark};
    margin-top: ${theme.space[5]}px;
    margin-bottom: ${theme.space[5]}px;
  }
  blockquote {
    margin: 0;
    margin-top: ${theme.space[6]}px;
    margin-bottom: ${theme.space[6]}px;
    padding-left: ${theme.space[9]}px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-family: ${theme.fonts.serif};
    font-size: ${theme.fontSizes.h2};
    border-left: 1px solid ${theme.colors.brandBrown};
    p {
      font-family: ${theme.fonts.serif};
    }
  }
  cite {
    font-style: italic;
    font-size: ${theme.fontSizes.tiny};
    font-family: ${theme.fonts.sans};
  }
  address {
    font-style: normal;
  }
  figcaption {
    margin-top: ${theme.space[1]}px;
    font-size: ${theme.fontSizes.tiny};
  }
  h6 {
    font-size: ${theme.fontSizes.h6M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h6};
    }
  }
  h5 {
    font-size: ${theme.fontSizes.h5M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h5};
    }
  }
  h4 {
    font-size: ${theme.fontSizes.h4M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h4};
    }
  }
  h3 {
    font-size: ${theme.fontSizes.h3M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h3};
    }
  }
  h2 {
    font-size: ${theme.fontSizes.h2M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h2};
    }
  }
  h1 {
    font-size: ${theme.fontSizes.h1M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h1};
    }
  }
`;
const Content = ({ children }) => {
    return React.createElement(StyledContent, null, children);
};
export default Content;
