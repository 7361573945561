import { jsx } from '@emotion/react';
import { Heading as RebassHeading } from '../Rebass';
import theme from '../../theme';
const weight = {
    bold: {
        fontWeight: theme.fontWeights.headingBold,
    },
    normal: {
        fontWeight: theme.fontWeights.headingNormal,
    },
    light: {
        fontWeight: theme.fontWeights.headingLight,
    },
};
const display = {
    hero: {
        fontSize: ['heroM', 'hero'],
        fontWeight: 'headingNormal',
        lineHeight: 1,
    },
    h1: {
        fontSize: ['h1M', 'h1'],
        fontWeight: 'headingLight',
        lineHeight: 1.2,
    },
    h2: {
        fontSize: ['h2M', 'h2'],
        fontWeight: 'headingLight',
        lineHeight: 1.1667,
    },
    h3: {
        fontSize: ['h3M', 'h3'],
        fontWeight: 'headingLight',
        lineHeight: 1.43,
    },
    h4: {
        fontSize: ['h4M', 'h4'],
        fontWeight: 'headingLight',
        lineHeight: 1.25,
    },
    h5: {
        fontSize: ['h5M', 'h5'],
        fontWeight: 'headingNormal',
        lineHeight: 1.333,
        fontFamily: theme.fonts.sans,
    },
    h6: {
        fontSize: ['h6M', 'h6'],
        lineHeight: 1.333,
    },
    h7: {
        fontSize: ['h7M', 'h7'],
        lineHeight: 1.5,
    },
    menu: {
        fontSize: ['menuM', 'menu'],
    },
};
const Heading = (props) => {
    const { children, size, fontWeight, ...rest } = props;
    const headingProps = { ...rest, ...display[size], ...weight[fontWeight] };
    return jsx(RebassHeading, Object.assign({}, headingProps), children);
};
Heading.defaultProps = {
    size: 'h1',
    fontFamily: 'serif',
    color: 'textBlack',
};
export default Heading;
