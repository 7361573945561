import { useViewportScroll, useTransform } from '../components/Motion';
const useHeroParallax = () => {
    const { scrollY } = useViewportScroll();
    const letterSpacing = useTransform(scrollY, [0, 800], ['0em', '0.5em']);
    const titleY = useTransform(scrollY, [0, 1000], [0, -400]);
    const titleOpacity = useTransform(scrollY, [0, 400], [1, 0]);
    const captionOpacity = useTransform(scrollY, [0, 400], [1, 0]);
    const scale = useTransform(scrollY, [0, 800], [1, 1.15]);
    const headlineStyle = {
        letterSpacing,
        y: titleY,
        opacity: titleOpacity,
    };
    const backgroundStyle = {
        scale,
    };
    const captionStyle = {
        opacity: captionOpacity,
    };
    return { headlineStyle, backgroundStyle, captionStyle };
};
export default useHeroParallax;
