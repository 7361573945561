import React from 'react';
import { Box, Flex } from '../Rebass';
import Heading from '../Heading';
import Text from '../Text';
const SectionHeading = ({ headline, headlineLineTwo, description, alignment, headlineSize, renderCta, direction, styles, }) => {
    const sectionHeadlineWidth = (alignment, direction) => {
        if (alignment === 'center') {
            return [1, 1, 6 / 12];
        }
        else {
            return [1];
        }
    };
    const sectionDescriptionWidth = (alignment, direction) => {
        if (alignment === 'center') {
            return [1, 1, 6 / 12];
        }
        else if (direction === 'column' && alignment !== 'center') {
            return [1, 1, 5 / 12];
        }
        else {
            return [1];
        }
    };
    return (React.createElement(Flex, { pb: !description ? [4] : [6], alignItems: alignment === 'center' ? 'center' : 'flex-start', flexDirection: 'column' },
        headline && (React.createElement(Box, { className: "sectionHeadline", width: sectionHeadlineWidth(alignment, direction), sx: styles?.headline ?? {} },
            React.createElement(Heading, { as: headlineSize, size: headlineSize, textAlign: alignment, fontWeight: "normal" },
                React.createElement(Box, null, headline),
                headlineLineTwo && React.createElement(Box, null, headlineLineTwo)))),
        description && (React.createElement(Box, { pt: [3], width: sectionDescriptionWidth(alignment, direction), sx: styles?.description ?? {} },
            React.createElement(Text, { textAlign: alignment, as: 'p', size: "body" }, description))),
        renderCta && React.createElement(Box, { pt: [4] }, renderCta)));
};
SectionHeading.defaultProps = {
    headline: '',
    headlineLineTwo: '',
    description: '',
    headlineSize: 'h2',
    alignment: 'left',
    direction: 'column',
    renderCta: null,
};
export default SectionHeading;
