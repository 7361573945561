import gql from 'graphql-tag';

export const MUTATION_SUBMIT_FORM = gql`
  mutation submitTransactionForm($input: InputFormDetails!) {
    submitTransactionForm(input: $input) {
      transaction_id
      transaction_date
      hasHash
      hashdata
    }
  }
`;
