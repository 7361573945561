import styled from '@emotion/styled';
import React from 'react';
import { Box, Flex } from '../Rebass';
import Text from '../Text';
import { cardHeadlineStyle, cardWrapperStyle } from './common';
const CardWrapper = styled(Flex)(cardWrapperStyle);
const StyledHeading = styled(Text)(cardHeadlineStyle);
const CardSmall = ({ headline, description, image, renderCta, bg, padding, colorScheme, isLink, imageSx, }) => (React.createElement(CardWrapper, Object.assign({ flexDirection: "column", alignItems: "start", bg: bg }, padding),
    image ? (React.createElement(Box, { mb: [1], width: "100%", sx: imageSx }, image)) : null,
    headline && (React.createElement(Box, { mb: [1], sx: { textAlign: 'left' }, width: "100%" },
        React.createElement(StyledHeading, { className: "card-headline", isLink: isLink, size: "small", fontWeight: "bold" }, headline))),
    description ? (React.createElement(Box, { mb: [1], width: "100%", textAlign: "left" },
        React.createElement(Text, { size: "small" }, description))) : null,
    renderCta && React.createElement(Box, null, renderCta(colorScheme))));
CardSmall.defaultProps = {
    headline: '',
    description: '',
    image: null,
    renderCta: null,
    bg: 'transparent',
    padding: {},
    colorScheme: 'default',
};
export default CardSmall;
