import React from 'react';
import { Box, Flex } from '../Rebass';
import Text from '../Text';
import Heading from '../Heading';
import Meta from '../Meta';
import { cardHeadlineStyle, cardWrapperStyle } from './common';
import styled from '@emotion/styled';
const CardWrapper = styled(Flex)(cardWrapperStyle);
const StyledHeading = styled(Heading)(cardHeadlineStyle);
const CardStandard = ({ headline, description, image, renderCta, meta, bg, padding, colorScheme, children, highlight, isLink, imageSx, }) => (React.createElement(CardWrapper, Object.assign({ sx: { position: 'relative' }, flexDirection: "column", alignItems: "start", bg: bg }, padding),
    highlight && (React.createElement(Flex, { sx: {
            position: 'absolute',
            top: '12px',
            left: '12px',
            bg: 'white',
            zIndex: 1,
            borderRadius: '4px',
            padding: '4px 5px',
            maxWidth: '50%',
        } },
        React.createElement(Text, { size: "extraSmall", sx: { textTransform: 'uppercase', overflowWrap: 'break-word' } }, highlight))),
    image ? (React.createElement(Box, { mb: [3], width: "100%", sx: imageSx }, image)) : null,
    Boolean(meta.length) && (React.createElement(Box, { mb: [1], width: "100%" },
        React.createElement(Meta, { meta: meta, size: "small" }))),
    React.createElement(Box, { mb: [1], width: "100%" },
        React.createElement(StyledHeading, { className: "card-headline", isLink: isLink, size: "h4", fontWeight: 'normal' }, headline)),
    React.createElement(Box, { mb: [1], width: "100%" },
        React.createElement(Text, { size: "small" }, description)),
    renderCta && renderCta(colorScheme),
    children));
CardStandard.defaultProps = {
    headline: '',
    description: '',
    meta: [],
    image: null,
    renderCta: null,
    bg: 'transparent',
    padding: {},
    colorScheme: 'brandBrown',
};
export default CardStandard;
