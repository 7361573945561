import React from 'react';
import { Box, Flex } from '../Rebass';
import styled from '@emotion/styled';
import Heading from '../Heading';
import { cardHeadlineStyle, cardWrapperStyle } from './common';
const ImageWrapper = styled(Box) `
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 125%;
  }
`;
const ImageInner = styled(Flex) `
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
const CardWrapper = styled(Box)(cardWrapperStyle);
const StyledHeading = styled(Heading)(cardHeadlineStyle);
const CardLodge = ({ headline, subtitle, image, sx, isLink, }) => {
    return (React.createElement(CardWrapper, { sx: sx },
        image && React.createElement(Box, { mb: [2] }, image),
        React.createElement(Heading, { size: "h7", as: "p", mb: [1], color: "textDark", fontFamily: "sans" }, subtitle),
        React.createElement(StyledHeading, { className: "card-headline", isLink: isLink, size: "h4", fontWeight: 'normal' }, headline)));
};
export default CardLodge;
