import React from 'react';
import styled from '@emotion/styled';
import Text from '../Text';
import { Box, Flex } from '../Rebass';
import theme from '../../theme';
import { keyframes } from '@emotion/react';
const StyledLoader = styled(Flex)(({ size }) => `
  position: relative;
  width: ${size}px;
  height: ${size}px;
`);
const StyledText = styled(Text) `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-overflow: wrap;
`;
const spin = keyframes `
  100% {
    transform: rotate(360deg);
  }
`;
const StyledSVG = styled.svg `
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  animation: ${spin} 4s linear infinite;
`;
const path = 'M40.356326,46.95467 C48.565823,37.825008 67.398316,17.381461 99.208804,6.767666 C136.144791,-5.555129 167.971779,2.91342 186.574772,7.865025 C205.377266,12.8676 237.111254,21.313662 263.703244,50.609533 C291.985734,81.761319 296.814232,117.05968 298.431232,130.248969 C300.294231,145.441086 303.82223,177.921096 285.637736,212.083122 C266.398743,248.226989 234.936255,263.569019 221.66726,269.805372 C182.568274,288.186126 147.153287,283.942107 134.302791,282.22711 C120.081297,280.330718 89.563808,275.86333 60.462318,252.635911 C33.396328,231.033542 22.558832,205.17666 15.499835,188.338505 C-2.990658,144.226796 0.83734,106.321459 1.97434,97.005906 C4.150839,79.161839 6.315338,61.428707 19.155333,44.032878 C37.557327,19.102454 65.560817,11.890171 78.373812,8.595098 C148.885786,-9.5383 254.538248,24.745155 284.905737,103.215275 C299.521731,140.984192 291.937734,176.636347 290.023735,184.683642 C280.113238,226.374266 253.810748,250.829467 238.116254,265.421935 C223.201759,279.288828 205.057766,295.737211 175.974276,301.955575 C153.600284,306.739278 135.609291,303.148877 131.743792,302.321361 C85.398309,292.401661 52.753821,248.078576 41.452825,207.701184 C26.161831,153.055134 50.431822,107.31238 58.999819,91.159324 C65.721316,78.488732 85.114809,43.101922 126.991794,21.381122 C143.994288,12.563278 171.969278,-1.948238 204.852266,6.037593 C250.789749,17.19407 270.832742,63.642912 274.67124,73.25829 C302.19773,142.225466 251.278749,208.638136 246.523751,214.640626 C204.904766,267.195398 144.243288,273.676109 129.184793,275.287667 C106.525802,277.707252 73.063814,281.284161 43.645825,260.306926 C10.221337,236.473861 4.143339,196.718605 1.24384,177.7427 C0.48334,172.76561 -9.149656,102.018974 40.356326,46.95467 Z';
const SVG = ({ color, size }) => {
    return (React.createElement(StyledSVG, { style: { height: `${size}px`, width: `${size}px` }, viewBox: `0 0 310 310` },
        React.createElement("path", { d: path, fill: "transparent", stroke: theme.colors[color] })));
};
const Loader = ({ isActive = true, color, children, size = 100, hasMinHeight = false, }) => {
    return isActive ? (React.createElement(StyledLoader, { "data-testid": "loader", minHeight: hasMinHeight ? '100vh' : 'auto' },
        React.createElement(Box, { sx: {
                margin: 'auto',
                position: 'relative',
                width: `${size}px`,
                height: `${size}px`,
            } },
            React.createElement(SVG, { size: size, color: color }),
            children && (React.createElement(StyledText, null,
                React.createElement(Text, { size: "body", color: theme.colors[color], fontFamily: 'serif' }, children)))))) : null;
};
Loader.defaultProps = {
    color: 'textDark',
    isActive: true,
    size: 100,
    children: 'Loading...',
    hasMinHeight: false,
};
export default Loader;
