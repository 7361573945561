import React, { forwardRef } from 'react';
import CardSmall from './CardSmall';
import CardStandard from './CardStandard';
import CardFeatured from './CardFeatured';
import CardStrip from './CardStrip';
import CardHero from './CardHero';
import CardLodge from './CardLodge';
import CardText from './CardText';
import CardPoster from './CardPoster';
const cardVariants = {
    small: CardSmall,
    standard: CardStandard,
    featured: CardFeatured,
    strip: CardStrip,
    hero: CardHero,
    lodge: CardLodge,
    text: CardText,
    poster: CardPoster,
};
const Card = forwardRef(({ variant, children, ...props }, ref) => React.createElement(cardVariants[variant], { ...props, ref }, children));
export default Card;
