import React, { useState } from 'react';
import { Flex, Box } from '../Rebass';
import { motion } from '../Motion';
import Button from '../Button';
import Text from '../Text';
import Icon from '../Icon';
import theme, { breakpoints } from '../../theme';
import SectionHeading from '../SectionHeading';
import styled from '@emotion/styled';
import useWindowWidth from '../../hooks/useWindowWidth';
import useHasMounted from '../../hooks/useHasMounted';
const ImageContainer = styled(Box) `
  position: absolute;
  left: 15vw;
  width: 70vw;
  height: 100%;
  will-change: transform;
`;
const AnimatedBox = motion.custom(ImageContainer);
const transition = {
    // type: 'spring',
    // damping: 15,
    // stiffness: 50,
    duration: 1.5,
};
const GalleryTriptic = ({ headline, description, items, hasPagination, lodge, children, renderCta, headingStyles, ...props }) => {
    const hasMounted = useHasMounted();
    const [index, setIndex] = useState(0);
    const windowWidth = useWindowWidth();
    const childrenLength = React.Children.count(children);
    if (!hasMounted) {
        return null;
    }
    const isMobile = windowWidth < breakpoints.lg;
    const xFactor = isMobile ? 0.25 : 0.3;
    const moveLeft = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };
    const moveRight = () => {
        if (index < childrenLength - 1) {
            setIndex(index + 1);
        }
    };
    return (React.createElement(Box, Object.assign({}, props),
        headline && (React.createElement(SectionHeading, { headline: headline, alignment: 'center', styles: headingStyles })),
        description && (React.createElement(Flex, { px: [1, 1, 0], pb: [4], width: [1], justifyContent: "center" },
            React.createElement(Box, { width: [1, 6 / 12], textAlign: "center" }, description))),
        React.createElement(Box, { sx: {
                position: 'relative',
                height: ['250px', '400px', '400px', '75vh'],
                overflow: 'hidden',
            } },
            React.Children.map(children, (child, idx) => {
                const style = {
                    x: (idx - index) * (windowWidth - windowWidth * xFactor),
                    scale: index === idx || isMobile ? 1 : 0.9,
                };
                return (React.createElement(AnimatedBox, { key: `triptic-element-${idx}`, initial: style, animate: style, transition: transition }, React.cloneElement(child, {
                    canPlay: idx === index ? true : false,
                })));
            }),
            index > 0 && (React.createElement(Button, { variant: "icon", colorScheme: "baseWhite", icon: "chevron-left", onClick: moveLeft, sx: {
                    position: 'absolute',
                    top: '50%',
                    left: '20px',
                    transform: 'translateY(-50%)',
                    opacity: theme.opacity.medium,
                    ':hover': {
                        opacity: theme.opacity.full,
                    },
                    zIndex: 5,
                } })),
            index < childrenLength - 1 && (React.createElement(Button, { variant: "icon", disabled: index === childrenLength - 1, colorScheme: "baseWhite", icon: "chevron-right", onClick: moveRight, sx: {
                    position: 'absolute',
                    top: '50%',
                    right: '20px',
                    transform: `translateY(-50%)`,
                    opacity: theme.opacity.medium,
                    ':hover': {
                        opacity: theme.opacity.full,
                    },
                    zIndex: 5,
                } }))),
        React.createElement(Flex, { flexDirection: ['column', 'row'], justifyContent: ['center', 'space-between'], alignItems: ['center', 'flex-start'], mt: [1], sx: { transform: 'translateX(15vw)', width: '70vw' } },
            React.createElement(Flex, { alignItems: "center", justifyContent: "center", mt: [1], mb: [3, 0], flex: "0 1 auto" },
                React.createElement(Box, { flex: "1 0 auto", sx: { display: ['none', 'block'] } },
                    React.createElement(Text, { size: 'caption', sx: { textAlign: 'left' } },
                        index + 1,
                        "/",
                        childrenLength)),
                items[index] && items[index].desc && (React.createElement(React.Fragment, null,
                    React.createElement(Box, { sx: { display: ['none', 'block'] }, m: [1] },
                        React.createElement(Icon, { size: 5, name: "dot" })),
                    React.createElement(Text, { size: 'caption', sx: { textAlign: ['center', 'left'], mr: '16px' } }, items[index] && items[index].desc)))),
            renderCta && (React.createElement(Box, { id: "renderCta", flex: "0 0 auto" }, renderCta())))));
};
export default GalleryTriptic;
