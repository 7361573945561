import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '../Rebass';
import { AnimatePresence, motion } from '../Motion';
import Button from '../Button';
import useHasMounted from '../../hooks/useHasMounted';
const StyledVideo = styled.video `
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
`;
const ImagePreview = motion.custom(styled(Box) `
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
`);
const PlayOverlay = styled(Box) `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;
const Video = ({ fullUrl, previewUrl, renderThumbnail, canPlay = true, toggleModal, isModalOpen, visualRatio, }) => {
    const [isPreviewReady, setIsPreviewReady] = useState(false);
    const hasMounted = useHasMounted();
    let videoStyles = {};
    if (visualRatio) {
        videoStyles.paddingBottom = `${visualRatio}%`;
        videoStyles.position = 'relative';
    }
    if (!renderThumbnail && !previewUrl) {
        videoStyles.bg = 'brandBrown';
    }
    return (React.createElement(Box, { height: "100%", sx: videoStyles },
        renderThumbnail && (React.createElement(AnimatePresence, { initial: false }, (isModalOpen || !isPreviewReady) && (React.createElement(ImagePreview, { animate: { opacity: 1 }, exit: { opacity: 0 }, initial: { opacity: 1 } }, renderThumbnail())))),
        hasMounted && previewUrl && (React.createElement(StyledVideo, { autoPlay: true, loop: true, muted: true, controls: false, playsInline: true, onLoadedData: () => {
                if (canPlay) {
                    setIsPreviewReady(true);
                }
            } },
            React.createElement("source", { src: previewUrl, type: "video/mp4" }))),
        fullUrl && canPlay && (React.createElement(PlayOverlay, { onClick: () => toggleModal(fullUrl) },
            React.createElement(Button, { variant: "icon", size: "large", icon: "play", colorScheme: "transparent" })))));
};
export default Video;
