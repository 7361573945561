import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '../../Rebass';
import Text from '../../Text';
import Icon from '../../Icon';
import theme from '../../../theme';
const HiddenInput = styled.input `
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const NumberContainer = styled(Flex) `
  display: flex;
  margin-bottom: 1px;
  cursor: pointer;
  position: relative;
  ${(props) => props.disabled &&
    `
      cursor: not-allowed;
      opacity: 0.4; 
    `}
  text-transform: none;
`;
const StyledIcon = styled(Icon) `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${({ color }) => color ?? theme.colors.brandBrown};
  transition: color ${theme.speed.default} ease;
`;
const StyledControl = styled(Box) `
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid ${({ color }) => color ?? theme.colors.brandBrown};
  transition: background-color ${theme.speed.default} ease;

  &:hover {
    background-color: ${({ color }) => color ?? theme.colors.brandBrown};

    .icon {
      color: ${theme.colors.baseWhite};
    }
  }
`;
const CustomNumber = ({ value, increment, decrement, color }) => {
    return (React.createElement(Flex, { justifyContent: "space-between", alignItems: "center" },
        React.createElement(StyledControl, { "data-testid": "number-decrement", onClick: decrement, color: color },
            React.createElement(StyledIcon, { name: "minus", color: color })),
        React.createElement(Box, { mx: [1] },
            React.createElement(Text, { size: "small", color: "textDark" }, value)),
        React.createElement(StyledControl, { "data-testid": "number-increment", onClick: increment, color: color },
            React.createElement(StyledIcon, { name: "plus", color: color }))));
};
const Number = ({ disabled, label, description, initialValue = 0, orientation = 'left', min = 0, max = 100, value, onIncrement, onDecrement, color, error, ...props }) => {
    return (React.createElement(Box, null,
        React.createElement(NumberContainer, { size: "small", disabled: disabled, as: "label", alignItems: "center", justifyContent: "space-between", flexDirection: orientation === 'left' ? 'row' : 'row-reverse', mb: error ? [1] : [0] },
            React.createElement(HiddenInput, Object.assign({ type: "number", disabled: disabled, value: value }, props)),
            React.createElement(CustomNumber, { value: value, increment: () => (value < max ? onIncrement() : null), decrement: () => (value > min ? onDecrement() : null), color: error ? theme.colors.statusError : color }),
            React.createElement(Flex, { flexDirection: "column" },
                React.createElement(Text, { size: "small", color: "textDark" }, label),
                description && (React.createElement(Text, { size: "tiny", color: "brandBrown" }, description)))),
        error && (React.createElement(Text, { color: "statusError", size: "small" }, error))));
};
export default Number;
