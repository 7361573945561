import theme from '../theme';
import { isArray } from 'lodash';
export function resolveColor(color) {
    if (color in theme.colors) {
        return theme.colors[color];
    }
    else {
        return color;
    }
}
export function getWindowWidth() {
    if (typeof window !== 'undefined') {
        return window.innerWidth;
    }
    else {
        return 0;
    }
}
const GAPS = {
    COLUMN: 'column',
    ROW: 'row',
    GRID: 'grid',
};
const MARGINS = {
    [GAPS.COLUMN]: ['margin-right'],
    [GAPS.ROW]: ['margin-bottom'],
    [GAPS.GRID]: ['margin-right', 'margin-bottom'],
};
function getGapCss(gap, type) {
    let styles = '';
    if (!gap)
        return styles;
    if (isArray(gap)) {
        gap = gap[gap.length - 1];
    }
    if (typeof gap === 'number') {
        gap = `${theme.space[gap]}px`;
    }
    styles += `
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      & > * {
        ${MARGINS[type].reduce((acc, val) => {
        acc += `${val}: ${gap} !important;`;
        return acc;
    }, '')}
      }
    }
  `;
    return styles;
}
export function autoprefix(properties) {
    const { gridTemplateColumns, gridGap, gap: _gap, rowGap, columnGap, } = properties;
    let styles = '';
    const repeatRegex = /repeat\((\d+), *(.+)\)/;
    if (gridTemplateColumns) {
        let val;
        if (isArray(gridTemplateColumns)) {
            val = gridTemplateColumns[gridTemplateColumns.length - 1].replace(repeatRegex, '($2)[$1]');
        }
        else if (typeof gridTemplateColumns === 'string') {
            val = gridTemplateColumns.replace(repeatRegex, '($2)[$1]');
        }
        styles += `-ms-grid-columns: ${val};`;
    }
    if (gridGap || _gap) {
        let gap = gridGap || _gap;
        styles += getGapCss(gap, GAPS.GRID);
    }
    if (rowGap) {
        let gap = rowGap;
        styles += getGapCss(gap, GAPS.ROW);
    }
    if (columnGap) {
        let gap = columnGap;
        styles += getGapCss(gap, GAPS.COLUMN);
    }
    return styles;
}
