import { createElement } from 'react';
import ButtonStandard from './ButtonStandard';
import ButtonText from './ButtonText';
import ButtonIcon from './ButtonIcon';
const buttonVariants = {
    primary: ButtonStandard,
    outline: ButtonStandard,
    text: ButtonText,
    icon: ButtonIcon,
    simple: ButtonStandard,
};
const Button = ({ variant = 'primary', children, ...props }) => createElement(buttonVariants[variant], { ...props, variant }, children);
export default Button;
