import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '../Rebass';
import Heading from '../Heading';
import ButtonIcon from '../Button/ButtonIcon';
import theme from '../../theme';
import { useScrollPosition } from '../../hooks/useScrollPosition';
const StyledFilterWrapper = styled(Box) `
  position: relative;
  position: relative;
  padding-right: ${(isVertical) => (!isVertical ? '40px' : '0px')};
  align-items: 'center';
`;
const StyledFilter = styled(Flex) `
  align-items: ${(isVertical) => (isVertical ? 'flex-start' : 'center')};
  & > * {
    flex-shrink: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
`;
const StyledArrowWrapper = styled(Flex) `
  position: absolute;
  top: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: ${theme.zIndex.filterArrows};
  &.arrow-right {
    right: 0;
    &::before {
      background: linear-gradient(
        to right,
        rgba(244, 241, 236, 0.2) 20%,
        rgba(244, 241, 236, 1) 100%
      );

      height: 100%;
      width: 50px;
      content: '';
      pointer-events: none;
    }
  }
  &.arrow-left {
    left: 0;
    &::after {
      background: linear-gradient(
        to left,
        rgba(244, 241, 236, 0.2) 20%,
        rgba(244, 241, 236, 1) 100%
      );
      height: 100%;
      width: 50px;
      content: '';
      pointer-events: none;
    }
  }
`;
const Filter = ({ headline, isVertical = false, children, hasScrollOverflow = true, color, }) => {
    const leftPaddle = useRef(null);
    const rightPaddle = useRef(null);
    const filter = useRef(null);
    const wrapper = useRef(null);
    const [menuWrapperSize, setMenuWrapperSize] = useState(0);
    const [menuInvisibleSize, setMenuInvisibleSize] = useState(0);
    const paddleMargin = 20;
    const getMenuWrapperSize = () => {
        return wrapper.current.offsetWidth;
    };
    const getMenuSize = () => {
        return filter.current.scrollWidth;
    };
    var getMenuPosition = () => {
        return filter.current.scrollLeft;
    };
    function changeWindowSize() {
        setMenuWrapperSize(getMenuWrapperSize());
        setMenuInvisibleSize(getMenuSize() - getMenuWrapperSize());
        invisibleSet(getMenuSize() - getMenuWrapperSize());
    }
    useEffect(() => {
        changeWindowSize();
        window.addEventListener('resize', changeWindowSize);
        return () => {
            window.removeEventListener('resize', changeWindowSize);
        };
    }, []);
    const onLeftPaddleClick = () => {
        if (!Boolean(filter.current.scrollTo)) {
            filter.current.scrollLeft = 0;
        }
        else {
            filter.current.scrollTo({ left: 0, behavior: 'smooth' });
        }
    };
    const onRightPaddleClick = () => {
        filter.current.scrollLeft += menuInvisibleSize / 2;
    };
    const invisibleSet = (size) => {
        if (!isVertical && hasScrollOverflow) {
            let arr = ['none', 'none'];
            if (size === 0) {
                arr[0] = 'none';
                arr[1] = 'none';
            }
            else {
                const menuPosition = getMenuPosition();
                const menuEndOffset = size - paddleMargin;
                if (menuPosition >= size) {
                    arr[0] = 'flex';
                    arr[1] = 'none';
                }
                else if (menuPosition <= paddleMargin) {
                    arr[0] = 'none';
                    arr[1] = 'flex';
                }
                else if (menuPosition < menuEndOffset) {
                    arr[0] = 'flex';
                    arr[1] = 'flex';
                }
                else if (menuPosition >= menuEndOffset) {
                    arr[0] = 'flex';
                    arr[1] = 'none';
                }
            }
            if (leftPaddle && leftPaddle.current) {
                leftPaddle.current.style.display = arr[0];
            }
            if (rightPaddle && rightPaddle.current) {
                rightPaddle.current.style.display = arr[1];
            }
        }
    };
    useScrollPosition(() => {
        invisibleSet(getMenuSize() - getMenuWrapperSize());
    }, [menuWrapperSize], null, false, 100, filter);
    return (React.createElement(React.Fragment, null,
        headline && React.createElement(Heading, { size: "h3" }, headline),
        React.createElement(StyledFilterWrapper, { className: "StyledFilterWrapper", ref: wrapper },
            !isVertical && hasScrollOverflow && (React.createElement(StyledArrowWrapper, { ref: leftPaddle, className: "arrow-left", color: theme.colors.baseWhite, onClick: onLeftPaddleClick },
                React.createElement(ButtonIcon, { icon: "chevron-left", size: 'small', colorScheme: color, iconColor: "baseWhite" }))),
            React.createElement(StyledFilter, { ref: filter, className: "StyledFilter", width: isVertical ? 'auto' : '100%', height: isVertical ? '100%' : 'auto', flexDirection: isVertical ? 'column' : 'row', sx: {
                    overflowX: 'auto',
                    ...(hasScrollOverflow
                        ? isVertical
                            ? { overflowY: 'scroll' }
                            : { overflowX: 'scroll' }
                        : {}),
                } }, children),
            !isVertical && hasScrollOverflow && (React.createElement(StyledArrowWrapper, { ref: rightPaddle, className: "arrow-right", color: theme.colors.baseWhite, onClick: onRightPaddleClick },
                React.createElement(ButtonIcon, { icon: "chevron-right", size: 'small', colorScheme: color, iconColor: "baseWhite" }))))));
};
export default Filter;
