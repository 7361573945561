import React from 'react';
import { Flex, Box } from '../Rebass';
import styled from '@emotion/styled';
import theme from '../../theme';
import Heading from '../Heading';
import Text from '../Text';
import Button from '../Button';
import { AnimatePresence, motion } from '../Motion';
import { Translation } from 'react-i18next';
export const Overlay = styled(Box) `
  width: 100%;
  height: 100%;
  background: rgba(31, 31, 31, 0.8);
  position: fixed;
  top: 0;
  left: 0;
`;
export const ModalContainer = styled(Box)(({ width, maxHeight, minHeight, borderRadius }) => `
  width: ${width};
  max-height: ${maxHeight};
  min-height: ${minHeight};
  border-radius: ${borderRadius};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media(max-width: ${theme.breakpoints[2]}) {
    width: 100%;
    min-height: 100%;
  }
`);
const AnimatedModal = motion.custom(ModalContainer);
const configMap = {
    small: {
        width: '40%',
        borderRadius: '4px',
        height: '80%',
    },
    medium: {
        width: '50%',
        borderRadius: '4px',
        height: '80%',
    },
    large: {
        width: '100%',
        borderRadius: '0px',
        height: '100%',
    },
};
const StyledHeader = styled(Flex) `
  position: sticky;
  top: 0;
  left: 0;
  border-bottom: 1px solid ${theme.colors.baseGrayVeryLight};
  z-index: 1000;
  align-items: center;
  background-color: ${(props) => props.bg};
`;
const StyledFooter = styled(Box) `
  position: sticky;
  bottom: 0;
  left: 0;
  border-top: 1px solid ${theme.colors.baseGrayVeryLight};
`;
const animationVariants = {
    enter: (isLarge) => ({
        y: isLarge ? '-50%' : '-50%',
        opacity: 1,
        transition: {
            type: 'spring',
            damping: 15,
            stiffness: 50,
        },
    }),
    initial: (isLarge) => ({
        y: isLarge ? '-50%' : '-40%',
        opacity: 0,
    }),
    exit: {
        opacity: 0,
        transition: {
            duration: 0.3,
        },
    },
};
export const LargeHeader = ({ title, subtitle, handleClose, bg, closeText = '', }) => (React.createElement(StyledHeader, { id: "large-modal-header", py: [2], px: [4, 4, 10], bg: bg },
    React.createElement(Flex, { maxWidth: '1280px', justifyContent: "space-between", width: "100%", mx: "auto", alignItems: "baseline" },
        React.createElement(Box, null,
            React.createElement(Heading, { size: "h6" }, title),
            subtitle ? (React.createElement(Text, { color: "brandBrown", size: "tiny" }, subtitle)) : null),
        React.createElement(Button, { "data-testid": "modal-close", variant: "text", icon: "close", iconPosition: "right", onClick: handleClose }, closeText ? closeText : React.createElement(Translation, null, (t) => t('Close'))))));
const SmallHeader = ({ title, subtitle, handleClose, bg }) => (React.createElement(StyledHeader, { py: [2], pl: [5], pr: [2], bg: bg },
    React.createElement(Flex, { width: "100%", justifyContent: "space-between", alignItems: "center" },
        React.createElement(Box, null,
            React.createElement(Heading, { size: "h6" }, title),
            subtitle ? (React.createElement(Text, { color: "brandBrown", size: "tiny" }, subtitle)) : null),
        React.createElement(Button, { "data-testid": "modal-close", variant: "text", icon: "close", iconPosition: "right", onClick: handleClose }))));
const ConditionalAnimation = ({ isPageModal, children, isLarge }) => {
    return isPageModal ? (React.createElement(React.Fragment, null, children)) : (React.createElement(AnimatePresence, { initial: false, custom: isLarge }, children));
};
const Modal = ({ size = 'medium', modalFooter, children, isOpen, handleClose, title, subtitle, bg = 'baseWhite', sx = {}, contentProps = {}, isPageModal = false, maxWidth = '1280px', }) => {
    const { width, height, borderRadius } = configMap[size];
    const isLarge = size === 'large';
    const style = { zIndex: 'modal', ...sx };
    return (React.createElement(ConditionalAnimation, { isPageModal: isPageModal, isLarge: isLarge }, isOpen ? (React.createElement(Overlay, { "data-testid": "modal-overlay", onClick: () => (!isLarge ? handleClose() : null), sx: style },
        React.createElement(AnimatedModal, { id: "modal-container", "data-testid": "modal-container", key: "modal-container", width: width, height: height, borderRadius: borderRadius, bg: bg, onClick: (e) => e.stopPropagation(), variants: animationVariants, style: { x: '-50%' }, animate: "enter", initial: "initial", exit: "exit", custom: isLarge },
            React.createElement(Flex, { id: "modal-scrollable-content", "data-testid": "modal-scrollable-content", flexDirection: "column", height: "100%", sx: { overflowY: 'auto' } },
                !isLarge ? (React.createElement(SmallHeader, { title: title, subtitle: subtitle, handleClose: handleClose, bg: bg })) : (React.createElement(LargeHeader, { title: title, subtitle: subtitle, handleClose: handleClose, bg: bg })),
                React.createElement(Box, Object.assign({ mt: isLarge ? [0] : [2], px: isLarge ? [4, 4, 10] : [5], pt: isLarge ? [0] : [5], pb: [13], bg: bg, sx: { flex: '1 1 auto' } }, contentProps),
                    React.createElement(Box, { sx: { maxWidth: maxWidth, marginX: 'auto' } }, children)),
                modalFooter && (React.createElement(StyledFooter, { sx: { flex: '0 1 auto' }, bg: bg }, modalFooter)))))) : null));
};
export default Modal;
