import React from 'react';
import Input from './Input';
import Select from './Select';
import Selection from './Choice';
import Button from '../Button';
import Text from '../Text';
import Number from './Number';
import DatePicker from '../DatePicker';
import Heading from './../Heading';
import { Box, Flex } from '../Rebass';
import styled from '@emotion/styled';
const StyledForm = styled(Flex) `
  width: 100%;
`;
const FormHandler = ({ reference, fields, onSubmit, renderTitle, onChange, errors, formStyles, buttonText = 'Submit', dates, setDates, }) => {
    return (React.createElement(Box, null,
        renderTitle ? renderTitle() : null,
        React.createElement(StyledForm, Object.assign({ as: "form", onSubmit: onSubmit }, formStyles, { ref: reference }),
            fields.map((field) => {
                const { name, type, label, options, styles, placeholder, variant = 'standard', size, disabled, min, max, orientation, group = [], } = field;
                switch (type) {
                    case 'multiSelect':
                    case 'select':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Select, { label: label, placeholder: placeholder, options: options, onChange: onChange, name: name, error: errors[name] })));
                    case 'phone':
                    case 'input':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Input, { variant: variant, size: "standard", name: name, label: label, type: "input", bg: "white", onChange: onChange, error: errors[name] })));
                    case 'email':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Input, { variant: variant, size: "standard", name: name, label: label, type: "input", bg: "white", onChange: onChange, error: errors[name] })));
                    case 'textarea':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Input, { variant: variant, size: "standard", name: name, label: label, type: "textarea", bg: "white", onChange: onChange, error: errors[name] })));
                    case 'checkbox':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Selection, { label: label, orientation: "left", isSpaced: false, onChange: onChange, name: name })));
                    case 'radio':
                        return (React.createElement(Box, Object.assign({}, styles),
                            label && (React.createElement(Box, { mb: [1] },
                                React.createElement(Text, { size: "small", fontWeight: "bold" }, label))),
                            group.length &&
                                group.map((radioField) => (React.createElement(Box, { mb: [1] },
                                    React.createElement(Selection, { type: "radio", label: radioField.label, orientation: "left", isSpaced: radioField.isSpaced, onChange: onChange, name: name, value: radioField.value }))))));
                    case 'text':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Text, { size: size }, label)));
                    case 'heading':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Heading, { size: size }, label)));
                    case 'upload':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Button, { variant: "outline", width: '100%' }, label)));
                    case 'number':
                        return (React.createElement(Box, Object.assign({}, styles),
                            React.createElement(Number, { label: label, disabled: disabled, initialValue: 0, min: min, max: max, orientation: orientation, value: 1, onDecrement: () => console.log('onDecrement'), onIncrement: () => console.log('onIncrement') })));
                    case 'date':
                        return (React.createElement(Box, Object.assign({}, styles),
                            label && (React.createElement(Box, { mb: [1] },
                                React.createElement(Text, { size: "small", fontWeight: "bold" }, label))),
                            React.createElement(DatePicker, { startDate: dates.startDate, endDate: dates.endDate, setDates: () => { }, openDirection: "up", variant: "default" })));
                }
            }),
            React.createElement(Button, null, buttonText))));
};
export default FormHandler;
