import styled from '@emotion/styled';
import Text from '../Text';
import theme from '../../theme';
const FilterItem = styled(Text)(({ active, isVertical, activeColor }) => `
  position: relative;
  color: ${active ? theme.colors.textDark : theme.colors.textLight};
  padding: ${isVertical ? '0 8px' : '8px 0'};
  margin-right: ${theme.space[3]}px;
  margin-bottom: ${isVertical ? theme.space[3] : 0}px;
  font-weight: bold;

  will-change: opacity, color;
  cursor: pointer;
  white-space: nowrap;
  opacity: ${active ? theme.opacity.full : theme.opacity.medium};
  transition: opacity ${theme.speed.default} ease, color ${theme.speed.default} ease;

  &:hover {
    opacity: ${theme.opacity.full};
    color: ${theme.colors.textDark};
  }

  &:after {
    position: absolute;
    background-color: ${activeColor ?? theme.colors.brandBrown};
    opacity: ${active ? theme.opacity.full : theme.opacity.opaque};
    will-change: opacity;
    transition: opacity ${theme.speed.slow} ease;
    content: '';
    left: 0;
    ${isVertical
    ? `top: 0; height: 100%; width: 3px;`
    : `bottom: 0; height: 3px; width: 100%;`}
  }
`);
export default FilterItem;
