import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '../Rebass';
import Text from '../Text';
import { resolveColor } from '../../utils/helpers';
import theme from '../../theme';
const paddingMap = {
    left: {
        mr: [1],
    },
    right: {
        ml: [1],
    },
};
const sizeMap = {
    default: 'small',
    small: 'tiny',
    body: 'body',
};
const StyledIcon = styled.i `
  color: ${({ color }) => color};
  transition: color ${theme.speed.default} ease;
`;
const StyledText = styled(Text) `
  transition: color ${theme.speed.default} ease;
`;
const TextContainer = styled(Box) `
  position: relative;
  display: inline-block;
  width: fit-content;
  width: -moz-fit-content;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    opacity: ${theme.opacity.opaque};
    background-color: ${({ color }) => color};
    transition: opacity ${theme.speed.slow} ease;
    will-change: opacity;
    margin-bottom: -2px;
  }
`;
const ButtonText = ({ children, icon, iconPosition, colorScheme = 'textBlack', size = 'default', isSelected, selectedIcon, toggleSelected, onClick, fontWeight = 'normal', sx, ...rest }) => {
    const [isHovered, setHover] = useState(false);
    const color = resolveColor(colorScheme);
    const isIconLeft = iconPosition === 'left';
    const [iconOrder, textOrder] = isIconLeft ? [0, 1] : [1, 0];
    const padding = paddingMap[iconPosition];
    const textSize = sizeMap[size];
    const iconToDisplay = selectedIcon && (isSelected || isHovered) ? selectedIcon : icon;
    // const ButtonWrapper = icon ? Flex : Box
    const ButtonWrapper = styled(icon ? Flex : Box) `
    &:hover .button--text:after {
      opacity: ${theme.opacity.full};
    }
  `;
    return (React.createElement(ButtonWrapper, Object.assign({ sx: { cursor: 'pointer', ...sx }, 
        //onMouseEnter={() => setHover(true)}
        //onMouseLeave={() => setHover(false)}
        onClick: () => {
            toggleSelected && toggleSelected();
            onClick && onClick();
        } }, rest),
        React.createElement(TextContainer, { className: "button--text", color: color, sx: { order: textOrder } },
            React.createElement(StyledText, { size: textSize, color: color, fontWeight: fontWeight }, children)),
        icon && (React.createElement(Box, Object.assign({ sx: { order: iconOrder } }, padding),
            React.createElement(StyledIcon, { "data-testid": "button-icon", className: `icon icon-${iconToDisplay}`, color: color })))));
};
export default ButtonText;
