import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '../Rebass';
import Text from '../Text';
import { AnimatePresence, motion } from '../Motion';
import theme from '../../theme';
import Icon from '../Icon';
const StyledAccordian = styled(Box) `
  border-bottom: 1px solid ${theme.colors.baseGrayVeryLight};
`;
const StyledHeadline = styled(Flex) `
  cursor: pointer;

  i {
    font-size: 14px;
    transform: rotate(${({ isExpanded }) => (isExpanded ? '-90deg' : '90deg')});
    transition: transform ${theme.speed.default} linear;
  }
`;
const AnimatedChildren = motion.custom(styled(Box) `
  overflow: hidden;
`);
const Accordion = ({ headline, children, color }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (React.createElement(StyledAccordian, { width: [1] },
        React.createElement(StyledHeadline, { py: [3], pr: [1], justifyContent: "space-between", alignItems: "center", onClick: () => setIsExpanded(!isExpanded), isExpanded: isExpanded },
            React.createElement(Text, { size: "body", fontWeight: "bold", fontFamily: "serif", color: color }, headline),
            React.createElement(Icon, { name: "chevron-right", sx: {
                    transform: `rotate(${isExpanded ? 90 : 0}deg)`,
                    transition: `transform ${theme.speed.default} ease`,
                    color,
                } })),
        React.createElement(Box, { pb: [isExpanded ? 3 : 0] },
            React.createElement(AnimatePresence, { initial: false }, isExpanded && (React.createElement(AnimatedChildren, { key: "accordion-content", variants: {
                    open: { opacity: 1, height: 'auto' },
                    closed: { opacity: 0, height: 0 },
                }, animate: "open", initial: "closed", exit: "closed", transition: { duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] } }, children))))));
};
export default Accordion;
