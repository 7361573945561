import React from 'react';
import { Flex, Box } from '../Rebass';
import styled from '@emotion/styled';
import theme from '../../theme';
import Icon from '../Icon';
import Text from '../Text';
const StyledLegal = styled(Flex) `
  border-top: 1px solid ${theme.colors.borderDark};
`;
const StyledLegalLinks = styled(Flex) `
  i {
    font-size: 7px;
  }
`;
const Footer = ({ children, renderContact, renderSocial, renderPPM, renderLegal, bg = 'bgLighter', }) => {
    return (React.createElement(Box, { "data-testid": "footer", sx: { borderTop: `1px solid ${theme.colors.borderDark}` }, bg: bg, px: [3, 3, 10, 25], pt: [0, 0, 1, 9], pb: [6] },
        children ? (React.createElement(Flex, { flexDirection: ['column', 'column', 'column', 'row'], justifyContent: "space-between", mb: [5, 5, 5, 8] }, children)) : null,
        renderContact && React.createElement(Box, { mb: [3, 3, 3, 5] }, renderContact()),
        renderSocial && (React.createElement(Box, { sx: {
                display: ['block', 'block', 'block', 'none'],
                fontSize: ['26px', '26px', '26px', '22px'],
                mb: [2],
            } }, renderSocial())),
        React.createElement(StyledLegal, { sx: {
                pt: [2],
            }, justifyContent: "space-between", alignItems: ['center'] },
            renderLegal && (React.createElement(StyledLegalLinks, { "data-id": "Styled_Legal", alignItems: [
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center',
                ], flexDirection: ['column', 'column', 'column', 'column', 'row'] },
                React.createElement(Text, { size: "tiny", mb: [2, 2, 2, 2, 0] },
                    "All rights reserved \u00A9 ",
                    new Date().getFullYear(),
                    " Singita"),
                React.createElement(Box, { mx: [2], sx: { display: ['none', 'none', 'none', 'none', 'block'] } },
                    React.createElement(Icon, { name: "dot", size: 6, sx: { color: 'textDark' } })),
                renderLegal())),
            renderSocial && (React.createElement(Box, { "data-id": "Social_Large", sx: {
                    display: ['none', 'none', 'none', 'block'],
                    fontSize: ['26px', '26px', '26px', '22px'],
                    alignItems: ['center', 'center', 'center', 'flex-end'],
                    pl: [3],
                } }, renderSocial()))),
        renderPPM && (React.createElement(Box, { pt: [0, 0, 0, 1], pb: [4] }, renderPPM()))));
};
export default Footer;
