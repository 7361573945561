import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Box } from '../Rebass';
const StyledPopover = styled(Box) `
  border-radius: 4px;
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.05),
    0 5px 22px 4px rgba(0, 0, 0, 0.06), 0 7px 8px 0 rgba(0, 0, 0, 0.07);
  max-height: 400px;
  overflow-y: auto;
`;
const Popover = forwardRef(({ children, bg = 'baseWhite', sx }, ref) => (React.createElement(StyledPopover, { ref: ref, bg: bg, sx: { p: '5px', ...sx } }, React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
        return React.cloneElement(child, { width: '100%' });
    }
}))));
export default Popover;
