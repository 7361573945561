const styles = {
    base: {
        mx: 'auto',
        maxWidth: '1280px',
        px: ['30px', '15px'],
    },
    grid: {
        display: 'grid',
        px: 0,
    },
    tall: {
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
    },
    wide: {
        width: '100%',
    },
};
export default styles;
