import styled from '@emotion/styled';
import { Box } from '../../Rebass';
export const Container = styled(Box) `
  max-width: 1280px;
`;
export default Container;
Container.defaultProps = {
    mx: 'auto',
    px: '15px',
};
