import React from 'react';
import styled from '@emotion/styled';
import ReactPlayer from 'react-player';
import { AnimatePresence, motion } from '../Motion';
import { Overlay } from '../Modal';
import { Box } from '../Rebass';
import Icon from '../Icon';
const ModalContainer = styled(Box) `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const ModalInner = styled(Box) `
  position: relative;
  padding-top: 56.25%;
`;
const StyledPlayer = styled(ReactPlayer) `
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`;
const CloseIcon = styled(Box) `
  position: absolute;
  top: -32px;
  right: -32px;
  cursor: pointer;
`;
const AnimatedOverlay = motion.custom(Overlay);
const VideoModal = (props) => {
    const { isOpen, toggleIsOpen, videoUrl } = props;
    return (React.createElement(AnimatePresence, { initial: false }, isOpen ? (React.createElement(AnimatedOverlay, { "data-testid": "video-modal-overlay", exit: { opacity: 0 }, animate: { opacity: 1 }, initial: { opacity: 0 }, sx: { zIndex: 'videoModal' }, onClick: () => toggleIsOpen() },
        React.createElement(ModalContainer, { sx: { width: ['80%', '80%', '60%'] }, onClick: (e) => e.stopPropagation() },
            React.createElement(ModalInner, null,
                React.createElement(StyledPlayer, { url: videoUrl, playing: isOpen, controls: true, height: "100%", width: "100%" })),
            React.createElement(CloseIcon, { "data-testid": "close-modal-icon", onClick: () => toggleIsOpen() },
                React.createElement(Icon, { name: "close", size: 24, sx: { color: 'textWhite' } }))))) : null));
};
export default VideoModal;
