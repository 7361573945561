import React from 'react';
import styled from '@emotion/styled';
import { Box } from '../Rebass';
import theme from '../../theme';
import { resolveColor } from '../../utils/helpers';
const sizeMap = {
    default: {
        height: 50,
        width: 50,
        fontSize: 16,
    },
    small: {
        height: 30,
        width: 30,
        fontSize: 12,
    },
    large: {
        height: 80,
        width: 80,
        fontSize: 24,
    },
};
function getButtonColors(color, iconColor = theme.colors.textDark) {
    switch (color) {
        case theme.colors.transparent:
            return {
                bgColor: theme.colors.transparent,
                borderColor: theme.colors.textWhite,
                iconColor: theme.colors.textWhite,
                hoverIconColor: theme.colors.baseGrayDark,
                hoverBg: theme.colors.textWhite,
            };
        default:
            return {
                bgColor: color,
                borderColor: color,
                iconColor,
                hoverIconColor: theme.colors.textLight,
                hoverBg: color,
            };
    }
}
const StyledButton = styled(Box)(({ borderColor, sizeVariant, hasBorder, hoverBg, iconColor, bgColor, hoverIconColor, fontSize, }) => `
  cursor: pointer;
  border-radius: 50%;
  border-style: solid;
  border-color: ${borderColor};
  border-width: 0;
  background-color: ${bgColor};
  transition: background-color ${theme.speed.default} ease, opacity ${theme.speed.default} ease;

  i {
    font-size: ${fontSize}px;
    transition: color ${theme.speed.default} ease;
    color: ${iconColor ? iconColor : borderColor};
  }

  border-width: ${hasBorder || sizeVariant === 'large' ? '1px' : '0'};

  :hover {
    ${hoverBg ? `background-color: ${hoverBg};` : ''}
    i {
      color: ${hoverIconColor};
    }
  }
`);
const StyledIcon = styled.i `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const ButtonIcon = ({ size = 'default', icon, iconColor, colorScheme = 'brandBrown', ...props }) => {
    const colors = getButtonColors(resolveColor(colorScheme), resolveColor(iconColor));
    const { height, width, fontSize } = sizeMap[size];
    return (React.createElement(StyledButton, Object.assign({ height: height, width: width, fontSize: fontSize, sizeVariant: size }, colors, props),
        React.createElement(StyledIcon, { "data-testid": "button-icon", className: `icon icon-${icon}` })));
};
export default ButtonIcon;
