import React, { useState, useEffect } from 'react';
import { Flex, Box } from '../Rebass';
import styled from '@emotion/styled';
import theme from '../../theme';
import Text from '../Text';
const StyledFilter = styled(Box) `
  border-bottom: 3px solid transparent;
  border-color: ${({ active }) => active ? theme.colors.brandBrown : 'transparent'};
  cursor: pointer;
`;
const LodgeFilter = ({ items, onSetFilter, filteredFunc, }) => {
    const [active, setActive] = useState('all');
    useEffect(() => {
        onSetFilter(filteredFunc(items, active));
    }, [active]);
    return (React.createElement(Flex, null,
        React.createElement(StyledFilter, { onClick: () => setActive('all'), mr: [4], active: active === 'all' },
            React.createElement(Text, { fontWeight: "bold", size: "body" }, "All")),
        filteredFunc(items).map((menuItem) => {
            return (React.createElement(StyledFilter, { key: menuItem, mr: [4], onClick: () => setActive(menuItem), active: menuItem === active },
                React.createElement(Text, { fontWeight: "bold", size: "body" }, menuItem)));
        })));
};
export default LodgeFilter;
