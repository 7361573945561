import React from 'react';
import styled from '@emotion/styled';
import theme from '../../../theme';
import Text from '../../Text';
import Icon from '../../Icon';
import { Box, Flex } from '../../Rebass';
const CustomChoice = styled.span `
  border: 1px solid ${({ color }) => color ?? theme.colors.brandBrown};
  background: ${theme.colors.baseWhite};
  height: 30px;
  width: 30px;
  position: relative;
  border-radius: 50%;
  margin-right: ${theme.space[1]}px;
  transition: background-color ${theme.speed.default} ease,
    border-color ${theme.speed.default} ease;
  flex: 0 0 auto;
`;
const HiddenChoice = styled.input `
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + .choice__custom {
    background-color: ${({ color }) => color ?? theme.colors.brandBrown};

    .icon {
      opacity: 1;
    }
  }
`;
const ChoiceContainer = styled(Flex) `
  display: flex;
  margin-bottom: 1px;
  cursor: pointer;
  position: relative;
  ${(props) => props.disabled &&
    `
      cursor: not-allowed;
      opacity: 0.4;
    `}
  text-transform: none;

  &:hover .choice__custom {
    border-color: ${({ color }) => color ?? theme.colors.borderDark};
  }
`;
const StyledIcon = styled(Icon) `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${theme.speed.default} ease;

  .icon {
    opacity: 0;
    color: ${theme.colors.textWhite};
  }
`;
const Choice = ({ label, disabled, orientation = 'left', isSpaced = true, type = 'checkbox', onChange, name, value, color, error, ...props }) => {
    return (React.createElement(Box, null,
        React.createElement(ChoiceContainer, Object.assign({ size: "small", disabled: disabled }, props, { name: name, value: value, alignItems: "center", justifyContent: isSpaced ? 'space-between' : 'flex-start', flexDirection: orientation === 'left' ? 'row' : 'row-reverse', color: color, mb: error ? [1] : [0], as: "label" }),
            React.createElement(HiddenChoice, Object.assign({ id: name, type: type, disabled: disabled }, props, { onChange: onChange, name: name, color: color, value: value })),
            React.createElement(CustomChoice, { className: "choice__custom", color: error ? theme.colors.statusError : color },
                React.createElement(StyledIcon, { name: "check", size: 14 })),
            React.createElement(Text, { as: "label", htmlFor: name, size: "small", color: "textDark" }, label)),
        error && type === 'checkbox' && (React.createElement(Text, { color: "statusError", size: "small" }, error))));
};
export default Choice;
