import React from 'react';
import ReactSelect from 'react-select';
import theme from '../../../theme';
import { Box } from '../../Rebass';
import Text from '../../Text';
const multiValueBgMap = {
    [theme.colors.bgLight]: theme.colors.baseWhite,
    [theme.colors.baseWhite]: theme.colors.bgLight,
    [theme.colors.bgLighter]: theme.colors.baseWhite,
};
const hoverBgMap = {
    [theme.colors.bgLight]: theme.colors.bgLighter,
    [theme.colors.baseWhite]: theme.colors.bgLight,
    [theme.colors.bgLighter]: theme.colors.bgLight,
};
const textStyles = {
    fontFamily: theme.fonts.sans,
    color: theme.colors.textDark,
    fontSize: theme.fontSizes.tiny,
};
const styles = (bg, error, height) => ({
    placeholder: (styles) => ({
        ...styles,
        ...textStyles,
    }),
    control: (styles) => ({
        ...styles,
        backgroundColor: theme.colors[bg] || theme.colors.baseWhite,
        border: error ? `1px solid ${theme.colors.statusError}` : 'none',
        height: height || 48,
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: `${theme.space[1]}px`,
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: theme.colors[bg] || theme.colors.baseWhite,
    }),
    option: (styles, state) => ({
        ...styles,
        ...textStyles,
        cursor: 'pointer',
        backgroundColor: state.isFocused
            ? hoverBgMap[bg]
            : theme.colors.transparent,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: multiValueBgMap[bg],
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        fontFamily: theme.fonts.sans,
        fontSize: theme.fontSizes.tiny,
        color: theme.colors.textDark,
    }),
    multiValueRemove: () => ({
        padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.textDark,
        backgroundColor: theme.colors.transparent,
        cursor: 'pointer',
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        ...textStyles,
    }),
    singleValue: (styles) => ({
        ...styles,
        ...textStyles,
    }),
});
const Select = ({ label, options, onChange, value, placeholder, noOptionsMessage, bg, name, error, isMulti = false, isSearchable = false, height, disabled = false, defaultValue, }) => {
    const customStyles = styles(bg, error, height);
    return (React.createElement(Box, null,
        React.createElement(Box, { mb: error ? [1] : [0] },
            label && (React.createElement(Box, null,
                React.createElement(Text, { as: "label", htmlFor: name, size: "small", fontWeight: "bold" }, label))),
            React.createElement(ReactSelect, { name: name, "aria-label": label, isMulti: isMulti, isSearchable: isSearchable, onChange: onChange, options: options, value: value || '', placeholder: placeholder, noOptionsMessage: () => noOptionsMessage, styles: customStyles, classNamePrefix: "react-select", isDisabled: disabled, defaultValue: defaultValue })),
        error && (React.createElement(Text, { color: "statusError", size: "small" }, error))));
};
export default Select;
