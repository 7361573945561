import React from 'react';
import styled from '@emotion/styled';
import theme from '../../../theme';
const Mark = styled.span `
  border: 1px solid ${theme.colors.baseGrayLight};
  background: ${theme.colors.baseWhite};
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  position: relative;
  border-radius: ${({ type }) => (type === 'radio' ? '50%' : '0')};
  margin-right: ${theme.space[1]}px;
`;
const Label = styled.label `
  margin-bottom: 1px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  font-family: europa, sans-serif;
  color: rgb(72, 72, 72);
  line-height: 1.333;
  font-style: normal;

  &:hover ${Mark} {
    border-color: ${theme.colors.baseGrayDark};
  }
  text-transform: none;
`;
const HiddenInput = styled.input `
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + ${Mark} {
    border-color: ${theme.colors.baseGrayDark};
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: ${theme.colors.baseGrayDark};
      border-radius: ${({ type, ...rest }) => {
    return type === 'radio' ? '50%' : '0';
}};
      right: 0;
      bottom: 0;
      margin: 5px;
    }
  }
`;
const InputToggle = ({ label, type, disabled, ...inputProps }) => {
    return (React.createElement(Label, { mb: [2], alignItems: 'center', size: "small" },
        React.createElement("input", Object.assign({ disabled: disabled, type: 'checkbox' }, inputProps)),
        label));
};
export default InputToggle;
