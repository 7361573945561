import React, { useRef } from 'react';
import { Box, Flex } from '../Rebass';
import ImageComp from '../Image';
import SimpleCarousel from '../SimpleCarousel';
import Text from '../Text';
import Heading from '../Heading';
import theme from '../../theme';
const ResultLodge = ({ lodge, img, images, imgTitle, region, children, renderCta, size, }) => {
    const carouselRef = useRef();
    return (React.createElement(Box, { sx: {
            display: 'grid',
            gridTemplateColumns: [`1fr`, '1fr', `1fr 2fr`],
            mb: ['10px', '40px'],
        } },
        React.createElement(Box, { sx: { mt: [5, 5, 0] } },
            img && !images ? (React.createElement(ImageComp, { sx: {
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    maxHeight: '376px',
                    objectFit: 'cover',
                }, src: img, alt: imgTitle })) : null,
            images && images.length > 1 ? (React.createElement(SimpleCarousel, { ref: carouselRef, items: images }, images.map((i, index) => (React.createElement(ImageComp, { sx: {
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    maxHeight: '266px',
                    objectFit: 'cover',
                    '@media (min-width: 576px)': {
                        maxHeight: '376px',
                    },
                    '@media (min-width: 768px)': {
                        height: '100%',
                    },
                }, key: i.id, src: i.src, alt: i.alt, onLoad: (args) => {
                    if (index === 0 && carouselRef.current) {
                        carouselRef.current.updateHeight(
                        //@ts-ignore
                        args.target.getBoundingClientRect().height);
                    }
                } }))))) : null),
        React.createElement(Box, { sx: { ml: [0, 0, 3], mt: [3, 3, 0] } },
            lodge && (React.createElement(Heading, { size: "h3", color: theme.colors.textBlack, fontFamily: theme.fonts.variable, sx: { mb: 1 } }, lodge)),
            React.createElement(Flex, { justifyContent: "space-between", "align-items": "center" },
                region && (React.createElement(Text, { color: theme.colors.textDark, fontFamily: theme.fonts.variable, size: "body" }, region)),
                renderCta && React.createElement(Box, null, renderCta)),
            children && React.createElement(Box, null, children))));
};
ResultLodge.defaultProps = {
    lodge: null,
    img: null,
    imgTitle: null,
    region: null,
    results: null,
    renderCta: null,
    size: 'blog-image-size',
};
export default ResultLodge;
