import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '../Rebass';
import Logo from '../Logo';
import theme from '../../theme';
const StyledIconLabel = styled.span `
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
const StyledHeader = styled(Flex) `
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${theme.space[4]}px;
  padding-right: ${theme.space[4]}px;
`;
const Header = React.forwardRef(({ bg, homeLink, renderButton, logoColor, secondaryButtonColor, sx, }, ref) => {
    return (React.createElement(StyledHeader, { ref: ref, bg: bg, justifyContent: "center", alignItems: "center", py: [2], sx: sx },
        React.createElement(Flex, Object.assign({ flex: "0 1 auto", mr: [3] }, homeLink),
            React.createElement(StyledIconLabel, { className: "visually-hidden" }, "Singita Home"),
            React.createElement(Logo, { color: logoColor })),
        renderButton && renderButton(secondaryButtonColor)));
});
Header.defaultProps = {
    logoColor: 'white',
    renderButton: (_) => { },
};
export default Header;
