import React, { forwardRef } from 'react';
import { Flex, Box } from '../Rebass';
import Heading from '../Heading';
import styled from '@emotion/styled';
import Logo from '../Logo';
import theme from '../../theme';
import Image from '../Image';
import Pattern from '../Pattern';
const BannerContainer = styled(Flex) `
  text-align: center;
  min-height: 700px;
  align-items: center;
  justify-content: center;
`;
const Banner = forwardRef(({ headline, cite, image, showLogo = true, showPattern = false, patternProps, children, citeSx, sx, renderCta, }, ref) => {
    return (React.createElement(BannerContainer, { ref: ref, pt: [6, 12], pb: [10, 12], height: ['100%'], width: [1], sx: sx },
        React.createElement(Box, { px: ['30px', '15px'], sx: { maxWidth: '840px' } },
            React.createElement(Box, { sx: { position: 'relative' } },
                showPattern && React.createElement(Pattern, Object.assign({}, patternProps)),
                showLogo && React.createElement(Logo, { color: theme.colors.baseWhite }),
                headline && (React.createElement(Heading, { size: "h1", py: [4, 6], color: theme.colors.textWhite }, headline)),
                children && React.createElement(Box, { mb: [4, 6] }, children),
                cite && (React.createElement(Heading, { size: "h7", pb: [4, 6], fontFamily: 'variable', sx: { color: theme.colors.textWhite, ...citeSx } }, cite)),
                image && (React.createElement(Box, { mb: [2, 4], m: 'auto', width: ['120px'] },
                    React.createElement(Image, { src: image, alt: headline || null }))),
                renderCta && (React.createElement(Flex, { flexDirection: ['column', 'column', 'column', 'row'], alignItems: 'center', justifyContent: "center" }, renderCta()))))));
});
export default Banner;
