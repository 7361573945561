import { css } from '@emotion/react';
import theme from '../../theme';
export const inputStyle = css `
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border: none;
  color: ${theme.colors.textDark};
  &::placeholder {
    color: ${theme.colors.baseGray};
  }
  &:disabled {
    opacity: 0.6;
  }
`;
