import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box, Image } from '../Rebass';
import flagZA from '../../static/flagZA.png';
import flagUK from '../../static/flagUK.png';
import flagUSA from '../../static/flagUSA.png';
import flagSingapore from '../../static/flagSingapore.png';
import flagBrazil from '../../static/flagBrazil.png';
const StyledIconWrapper = styled(Box) `
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const StyledIcon = styled(Flex) `
  font-size: ${({ size }) => size}px;
`;
const StyledIconDuo = styled(Box) `
  font-size: ${({ size }) => size}px;
  position: absolute;
  left: 0;
  top: 0;
  :before {
    color: ${({ duoColor }) => duoColor};
  }
`;
const StyledIconLabel = styled.span `
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
const imageMap = {
    'flag-south-africa-image': flagZA,
    'flag-usa-image': flagUSA,
    'flag-brazil-image': flagBrazil,
    'flag-singapore-image': flagSingapore,
    'flag-uk-image': flagUK,
};
const iconType = (name) => {
    if (name.includes('duo')) {
        return 'duo';
    }
    if (name.includes('image')) {
        return 'image';
    }
};
const Icon = ({ name, duoColor, size, sx, ...rest }) => {
    return (React.createElement(StyledIconWrapper, Object.assign({ className: "StyledIconWrapper", sx: sx }, rest),
        React.createElement(StyledIconLabel, { className: "visually-hidden" }, name),
        iconType(name) !== 'image' && (React.createElement(StyledIcon, { justifyContent: "center", "data-testid": "icon", className: `icon icon-${name}`, size: size })),
        iconType(name) === 'duo' && (React.createElement(Box, null,
            React.createElement(StyledIconDuo, Object.assign({ "data-testid": "icon", className: `icon icon-${name}-color`, size: size, duoColor: duoColor, sx: sx }, rest)))),
        iconType(name) === 'image' && (React.createElement(Image, { width: size, src: imageMap[name] }))));
};
Icon.defaultProps = {
    name: 'close',
    size: 16,
    duoColor: null,
};
export default Icon;
