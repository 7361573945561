import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import Icon from '../Icon';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Box } from '../Rebass';
const DatePickerContainer = styled(Box)(({ variant, colorScheme }) => {
    const isMinimal = variant === 'minimal';
    return `
  .DateInput {
    width: ${isMinimal ? '80px' : '130px'};
  }

  .DateInput_input {
    cursor: pointer;
    font-family: ${isMinimal ? theme.fonts.sans : theme.fonts.serif};
    font-size: ${isMinimal ? '14px' : '16px'};
    font-weight: 400;
    ${isMinimal ? 'padding: 0px;' : ''}
    color: ${isMinimal ? theme.colors.textLight : theme.colors.textDark};
  }

  .DateRangePickerInput {
    ${isMinimal ? 'border: none;' : ''}
  }

  .DateInput_input__focused {
    border-bottom: 2px solid ${colorScheme ?? theme.colors.brandBrown};
  }

  .CalendarMonth_caption {
    font-family: ${theme.fonts.serif};
    line-height: 22px;
  }

  .DayPicker_weekHeader_li {
    font-family: var(--sans);
  }

  .CalendarDay {
    font-family: ${theme.fonts.serif};
  }

  .CalendarDay.CalendarDay__selected_start,
  .CalendarDay__selected_end,
  .CalendarDay__selected:hover,
  .CalendarDay__selected:active {
    background: ${colorScheme ?? theme.colors.brandBrown};
    border-color: ${colorScheme ?? theme.colors.brandBrown};
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span,
  .CalendarDay.CalendarDay__selected_span,
  .CalendarDay__default:hover {
    background: ${colorScheme ?? theme.colors.brandBrown};
    border-color: ${colorScheme ?? theme.colors.brandBrown};
    color: ${theme.colors.textWhite};
    opacity: 0.8;
  }

  .DayPicker {
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.05),
      0 5px 22px 4px rgba(0, 0, 0, 0.06), 0 7px 8px 0 rgba(0, 0, 0, 0.07);
  }

  .DateInput_fang {
    filter: drop-shadow(0px -9px 12px rgba(0, 0, 0, 0.5));
  }

  .DateInput_fangStroke {
    stroke: none;
  }
`;
});
const DatePicker = (props) => {
    const [focused, setFocused] = useState(null);
    const { startDate, endDate, setDates, openDirection, variant = 'default', isOpen, colorScheme, disabled, } = props;
    useEffect(() => {
        if (isOpen) {
            setFocused('startDate');
        }
    }, [isOpen]);
    return (React.createElement(DatePickerContainer, { variant: variant, colorScheme: colorScheme },
        React.createElement(DateRangePicker, { startDateId: "start-date", endDateId: "end-date", startDate: startDate, isOutsideRange: props.isOutsideRange ? props.isOutsideRange : () => false, endDate: endDate, onDatesChange: (dates) => setDates(dates), focusedInput: focused, onFocusChange: (focused) => setFocused(focused), numberOfMonths: 1, hideKeyboardShortcutsPanel: true, customArrowIcon: React.createElement(Icon, { name: "arrow-right", size: variant === 'minimal' ? 12 : 16, sx: { width: '24px' } }), displayFormat: "YYYY-MM-DD", openDirection: openDirection, disabled: disabled })));
};
export default DatePicker;
