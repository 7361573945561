import React from 'react';
import styled from '@emotion/styled';
import { Box } from '../Rebass';
import styles from './styles';
import { autoprefix } from '../../utils/helpers';
const Container = (props) => {
    const { base } = styles;
    const { sx } = props;
    return React.createElement(Box, Object.assign({}, props, { sx: { ...base, ...sx } }));
};
const WideContainer = (props) => {
    const { wide } = styles;
    const { sx } = props;
    return React.createElement(Container, Object.assign({}, props, { sx: { ...wide, ...sx } }));
};
const TallContainer = (props) => {
    const { tall } = styles;
    const { sx } = props;
    return React.createElement(Container, Object.assign({}, props, { sx: { ...tall, ...sx } }));
};
const Grid = styled(Box) `
  display: grid;
  display: -ms-grid;
  ${({ sx }) => autoprefix(sx)}
`;
const GridContainer = (props) => {
    const { base, grid } = styles;
    const { sx } = props;
    return React.createElement(Grid, Object.assign({}, props, { sx: { ...base, ...grid, ...sx } }));
};
const GridCell = styled(Box) `
  -ms-grid-column: ${({ index, cols }) => (index % cols) + 1};
  -ms-grid-row: ${({ index, cols }) => Math.floor(index / cols) + 1};
`;
Container.displayName = 'Container';
GridContainer.displayName = 'GridContainer';
TallContainer.displayName = 'TallContainer';
WideContainer.displayName = 'WideContainer';
export { Container, GridContainer, TallContainer, WideContainer, GridCell };
