import React from 'react';
import { Box, Flex } from '../Rebass';
import SectionHeading from '../SectionHeading';
const Section = ({ id, children, headline, headlineLineTwo, description, renderCta, direction, textContainerSx = {}, childrenContainerSx = {}, headlineAlignment = 'left', headlineSize = 'h2', headingStyles, ...rest }) => {
    const isRow = direction === 'row';
    return (React.createElement(Flex, Object.assign({ id: id, pb: [6, 6, 6, 16], pt: [4], flexDirection: ['column', 'column', direction], justifyContent: 'space-between', alignItems: "flex-start" }, rest),
        headline || description ? (React.createElement(Box, { className: "sectionLayout", width: textContainerSx.width || (isRow ? [1, 1, 5 / 12] : [1]), sx: {
                ...(isRow
                    ? {
                        position: ['static', 'static', 'sticky'],
                        top: [1],
                        left: 0,
                    }
                    : {}),
                ...textContainerSx,
            } },
            React.createElement(SectionHeading, { headline: headline, headlineLineTwo: headlineLineTwo, description: description, renderCta: renderCta, direction: direction, alignment: headlineAlignment, headlineSize: headlineSize, styles: headingStyles }))) : null,
        children && (React.createElement(Box, { width: childrenContainerSx.width || (isRow ? [1, 1, 8 / 12] : [1]), sx: { ...childrenContainerSx } }, children))));
};
Section.defaultProps = {
    headline: '',
    headlineLineTwo: '',
    description: '',
    children: null,
    renderCta: null,
    direction: 'column',
    headlineSize: 'h2'
};
export default Section;
