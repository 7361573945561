import React from 'react';
import styled from '@emotion/styled';
import { Box } from '../Rebass';
import Text from '../Text';
import theme from '../../theme';
import Link from 'gatsby-link';
const MetaSeparator = styled(Box) `
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin: 0 ${theme.space[1]}px;
  margin-bottom: 3px;
`;
const MetaContainer = styled(Box) `
  line-height: 1;
`;
const Meta = ({ meta, size }) => (React.createElement(MetaContainer, null, meta.map((item, idx) => {
    return (React.createElement(Box, { sx: { display: 'inline-block' }, key: idx },
        item.type === 'link' ? (React.createElement(Link, { to: item.to },
            React.createElement(Text, { size: size, color: item.color }, item.text))) : (React.createElement(Text, { size: size, color: item.color }, item.text)),
        idx !== meta.length - 1 && (React.createElement(MetaSeparator, { color: theme.colors.baseBlack }))));
})));
Meta.defaultProps = {
    size: 'small',
    meta: [],
    type: '',
};
export default Meta;
