import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '../Rebass';
import Icon from '../Icon';
import theme from '../../theme';
const StyledFlex = styled(Flex) `
  width: 100%;
  border-bottom: 0.5px solid ${theme.colors.baseGray};
`;
const AlertBanner = ({ children, isDismissible, onDismiss, bg = 'baseBlack', }) => {
    return (React.createElement(StyledFlex, { p: [1], bg: bg, justifyContent: "center", alignItems: "center" },
        React.createElement(Box, { mr: [2], sx: { maxWidth: '80%', overflowWrap: 'break-word' } }, children),
        isDismissible && (React.createElement(Box, { "data-testid": "close-icon", sx: {
                cursor: 'pointer',
                position: ['absolute', 'absolute', 'static'],
                top: [1],
                right: [2],
            }, onClick: () => onDismiss() },
            React.createElement(Icon, { name: "close", sx: { color: 'textWhite' } })))));
};
export default AlertBanner;
