import React from 'react';
import { Text as RebassText } from '../Rebass';
import theme from '../../theme';
export const weight = {
    bold: {
        fontWeight: theme.fontWeights.bold,
    },
    normal: {
        fontWeight: theme.fontWeights.normal,
    },
    light: {
        fontWeight: theme.fontWeights.light,
    },
};
export const display = {
    extraSmall: {
        fontSize: ['extraSmall'],
        fontFamily: theme.fonts.sans,
    },
    caption: {
        fontSize: ['tiny'],
        fontFamily: theme.fonts.serif,
    },
    tiny: {
        fontSize: ['tiny'],
        fontFamily: theme.fonts.sans,
    },
    small: {
        fontSize: ['small'],
        fontFamily: theme.fonts.sans,
    },
    body: {
        fontSize: ['body'],
        fontFamily: theme.fonts.sans,
        fontWeight: 'light',
    },
    large: {
        fontSize: ['large'],
        fontFamily: theme.fonts.sans,
        fontWeight: 'light',
    },
};
const Text = ({ children, size = 'body', fontWeight, ...rest }) => {
    const textProps = { ...display[size], ...weight[fontWeight], ...rest };
    return React.createElement(RebassText, Object.assign({}, textProps), children);
};
Text.defaultProps = {
    size: 'body',
    lineHeight: 1.333,
    as: 'span',
    fontStyle: 'normal',
    color: 'textDark',
};
export default Text;
