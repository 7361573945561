import React from 'react';
import { Box } from '../Rebass';
import Heading from '../Heading';
import styled from '@emotion/styled';
import theme from '../../theme';
import { cardHeadlineStyle, cardWrapperStyle } from './common';
const ImageWrapper = styled(Box) `
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(31, 31, 31, 0.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }
`;
const ImageInner = styled(Box) `
  height: 100%;
`;
const StyledContent = styled(Box) `
  position: absolute;
  z-index: 4;
  top: ${theme.space[0]}px;
  left: 0px;
  @media (min-width: ${theme.breakpoints[1]}) {
    top: ${theme.space[4]}px;
    left: ${theme.space[4]}px;
    right: unset;
  }
  @media (min-width: ${theme.breakpoints[2]}) {
    top: ${theme.space[20]}px;
    left: ${theme.space[16]}px;
    right: unset;
  }
`;
const CardWrapper = styled(Box)(cardWrapperStyle);
const StyledHeading = styled(Heading)(cardHeadlineStyle);
const CardPoster = ({ headline, visual, renderCta, colorScheme, sx, isLink, }) => (React.createElement(CardWrapper, { sx: { height: ['100%'], ...sx } },
    React.createElement(ImageWrapper, { sx: { height: ['100%'] } },
        React.createElement(ImageInner, { className: "ImageInner" }, visual && typeof visual === 'function' ? visual() : visual),
        React.createElement(StyledContent, { p: [3] },
            headline && (React.createElement(Box, { mb: [1] },
                React.createElement(StyledHeading, { className: "card-headline", isLink: isLink, size: "h2", color: "textWhite", fontWeight: "normal" }, headline))),
            renderCta && React.createElement(Box, { mt: [1, 3] }, renderCta(colorScheme))))));
CardPoster.defaultProps = {
    headline: '',
    image: null,
    renderCta: null,
    colorScheme: 'textWhite',
};
export default CardPoster;
