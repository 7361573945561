import React, { forwardRef, useLayoutEffect, useRef, useState, } from 'react';
import { Box, Flex } from '../Rebass';
import Heading from '../Heading';
import Pattern, { LAYOUT_TYPES, PATTERN_POSITIONS } from '../Pattern';
import styled from '@emotion/styled';
import { cardHeadlineStyle, cardWrapperStyle } from './common';
import { motion, useViewportScroll, useTransform } from '../Motion';
const CardWrapper = styled(Flex)(cardWrapperStyle);
const StyledHeading = styled(Heading)(cardHeadlineStyle);
const imageAnimation = {
    animate: {
        opacity: 1,
    },
    initial: {
        opacity: 0,
    },
};
const textAnimation = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeOut',
        },
    },
    initial: {
        opacity: 0,
    },
};
const AnimatedBox = motion.custom(Box);
const CardHero = forwardRef(({ headline, subtitle, description, renderCta, colorScheme, visualPosition = 'left', bg = 'transparent', visual, children, showPattern, patternProps, patternPosition, isLink, shouldAnimate, subtitleSx, headlineSx, }, ref) => {
    const [offsetTop, setOffsetTop] = useState(0);
    const { scrollY } = useViewportScroll();
    const imageRef = useRef(null);
    useLayoutEffect(() => {
        if (imageRef.current) {
            setOffsetTop(imageRef.current.offsetTop);
        }
    }, [imageRef]);
    const imageY = useTransform(scrollY, [offsetTop - 300, offsetTop + 300], ['-50px', '100px']);
    const isLeftOriented = visualPosition === 'left';
    const imagePadding = {
        pl: [
            isLeftOriented ? 0 : 3,
            isLeftOriented ? 0 : 3,
            isLeftOriented ? 0 : 8,
            0,
        ],
        pr: [
            isLeftOriented ? 3 : 0,
            isLeftOriented ? 3 : 0,
            isLeftOriented ? 8 : 0,
            0,
        ],
    };
    return (React.createElement(CardWrapper, { ref: ref, bg: bg, flexDirection: [
            'column',
            'column',
            'column',
            isLeftOriented ? 'row-reverse' : 'row',
        ], alignItems: "flex-start" },
        React.createElement(AnimatedBox, { width: [1, 1, 1, 7 / 12], pl: [3, 3, 8, 10], pr: [3, 3, 8, 0], py: [4, 4, 10, 6], sx: {
                position: ['static', 'static', 'static', 'sticky'],
                top: '15%',
                left: 0,
            }, variants: textAnimation, animate: shouldAnimate ? 'animate' : 'initial', initial: "initial" },
            React.createElement(Flex, { flexDirection: "column", justifyContent: "center", width: [1, 1, 1, 8 / 12], height: "100%" },
                subtitle && (React.createElement(Box, { mb: [4] },
                    React.createElement(Heading, { size: "h5", color: "textDark", sx: { ...subtitleSx } }, subtitle))),
                headline && (React.createElement(Box, { mb: [4] },
                    React.createElement(StyledHeading, { className: "card-headline", isLink: isLink, size: "h1", sx: { ...headlineSx } }, headline))),
                children && React.createElement(Box, { mb: [4] }, children),
                renderCta && React.createElement(Box, null, renderCta(colorScheme)))),
        React.createElement(AnimatedBox, { ref: imageRef, width: [1, 1, 1, 5 / 12], height: "100%", pl: imagePadding.pl, pr: imagePadding.pr, sx: {
                position: ['static', 'static', 'static', 'sticky'],
                top: '15%',
                ...(isLeftOriented ? { left: 0 } : { right: 0 }),
            }, variants: imageAnimation, animate: shouldAnimate ? 'animate' : 'initial', initial: "initial", transition: {
                type: 'spring',
                damping: 15,
                stiffness: 50,
            } },
            React.createElement(Box, { sx: { overflow: 'hidden' } },
                React.createElement(motion.div, { style: { y: imageY } }, visual)),
            showPattern && (React.createElement(Pattern, Object.assign({ layoutType: patternPosition === PATTERN_POSITIONS.TOP
                    ? LAYOUT_TYPES.CARDHERO_TOP
                    : LAYOUT_TYPES.CARDHERO_BOTTOM }, patternProps))))));
});
export default CardHero;
