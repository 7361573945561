import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '../Rebass';
import Heading from '../Heading';
import Text from '../Text';
import Icon from '../Icon';
import theme from '../../theme';
const StyledCard = styled(Flex) `
  position: relative;
  border-radius: 4px;
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.1),
    0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px 0 rgba(0, 0, 0, 0.14);
  overflow: hidden;
`;
const CloseIcon = styled(Box) `
  position: absolute;
  top: ${theme.space[2]}px;
  right: ${theme.space[3]}px;
  cursor: pointer;
`;
const AlertStandard = ({ renderImage, headline, subtitle, isDismissible, children, onDismiss = () => { }, bg = 'bgLight', }) => {
    return (React.createElement(StyledCard, { flexDirection: ['column', 'column', 'row', 'row'], bg: bg, "data-testid": "alert" },
        isDismissible && (React.createElement(CloseIcon, { "data-testid": "close-icon", onClick: () => onDismiss() },
            React.createElement(Icon, { name: "close", size: 16, sx: { color: theme.colors.textDark } }))),
        renderImage && (React.createElement(Box, { sx: {
                display: ['none', 'none', 'block', 'block'],
                flexShrink: 0,
                width: '150px',
            } }, renderImage())),
        React.createElement(Box, { pl: [4], pr: [6], pt: [3], pb: [2], width: [1] },
            headline && (React.createElement(Box, { mb: [1] },
                React.createElement(Heading, { size: "h4", color: "textDark" }, headline))),
            subtitle && (React.createElement(Box, { mb: [3] },
                React.createElement(Text, { size: "small", color: "textDark" }, subtitle))),
            children && React.createElement(Box, null, children))));
};
export default AlertStandard;
