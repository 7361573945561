import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '../../Rebass';
import Button from '../../Button';
import Text from '../../Text';
import theme from '../../../theme';
const HiddenInput = styled.input `
  display: none;
`;
const InputContainer = styled(Flex) `
  border-radius: 4px;
  padding: 8px;
  ${({ error }) => error ? `border: 1px solid ${theme.colors.statusError};` : ''}
`;
const FileInput = ({ name, label, error, color, onChange, bg, value, buttonLabel, disabled, }) => {
    const hiddenFileInput = useRef(null);
    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        onChange(fileUploaded);
    };
    return (React.createElement(React.Fragment, null,
        label && (React.createElement(Box, null,
            React.createElement(Text, { as: "label", htmlFor: name, size: "small", fontWeight: "bold" }, label))),
        React.createElement(InputContainer, { alignItems: "center", bg: bg, mb: error ? [1] : [0], error: error },
            React.createElement(Box, { mr: [2] },
                React.createElement(Button, { variant: "outline", size: "small", colorScheme: color, onClick: handleClick, type: "button" }, buttonLabel)),
            value && React.createElement(Text, { size: "small" }, value.name)),
        error && (React.createElement(Text, { color: "statusError", size: "small" }, error)),
        React.createElement(HiddenInput, { ref: hiddenFileInput, name: name, "aria-label": label, onChange: handleChange, type: "file", disabled: disabled })));
};
export default FileInput;
