import React, { forwardRef } from 'react';
import { Box, Input, Textarea } from '../../Rebass';
import styled from '@emotion/styled';
import Text from '../../Text';
import { inputStyle } from '../styles';
import theme from '../../../theme';
import { css } from '@emotion/react';
import Icon from '../../Icon';
const styles = {
    standard: '',
    outline: css `
    background: transparent;
    border: none;
    border-bottom: 1px solid ${theme.colors.baseGrayLight};
    border-radius: 0;
  `,
};
const errorStyles = {
    standard: css `
    border: 1px solid ${theme.colors.statusError};
  `,
    outline: css `
    border-bottom: 1px solid ${theme.colors.statusError};
  `,
};
const StyledInput = styled(Input) `
  ${inputStyle}
  ${({ variant }) => styles[variant]}
  ${({ color }) => color
    ? `
        color: ${theme.colors[color]};
        &::placeholder {
          color: ${theme.colors[color]};
          opacity: 0.6;
        }`
    : ''}
      ${({ error, variant }) => (error ? errorStyles[variant] : '')}
`;
const StyledTextArea = styled(Textarea) `
  ${inputStyle}
  ${({ variant }) => styles[variant]}
  ${({ error, variant }) => (error ? errorStyles[variant] : '')}
`;
const inputMap = {
    input: StyledInput,
    textarea: StyledTextArea,
};
const TextInput = forwardRef(({ name, label, error, type = 'input', variant = 'standard', sx, icon, size, bg, onChange, ...input }, ref) => {
    const InputComponent = inputMap[type];
    return (React.createElement(Box, { sx: sx },
        label && (React.createElement(Box, null,
            React.createElement(Text, { as: "label", htmlFor: name, size: "small", fontWeight: "bold" }, label))),
        React.createElement(Box, { sx: { position: 'relative' }, mb: error ? [1] : [0] },
            React.createElement(InputComponent, Object.assign({ ref: ref, id: name, name: name, error: error, variant: variant, fontSize: size === 'large' ? ['menuM', 'menu'] : 'small', fontFamily: size === 'large' ? 'serif' : 'sans', fontWeight: size === 'large' ? 'normal' : 'light', bg: bg, "aria-label": label }, input, { onChange: onChange })),
            icon && (React.createElement(Box, { sx: {
                    position: 'absolute',
                    right: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                } },
                React.createElement(Icon, Object.assign({ size: 16 }, icon, { sx: { color: 'textDark', ...icon.sx } }))))),
        error && (React.createElement(Text, { color: "statusError", size: "small" }, error))));
});
export default TextInput;
