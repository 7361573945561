import React, { useEffect, useRef, useState, forwardRef, useLayoutEffect, } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '../Rebass';
import Text from '../Text';
import Heading from '../Heading';
import theme from '../../theme';
import { motion } from '../Motion';
const StyledActionBar = styled(Flex)(({ isSticky, inView, color, offset }) => `
  ${color ? `border-left: 5px solid ${color};` : ''}
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${theme.zIndex.actionBar};
  height: ${isSticky && inView ? 'auto' : '0px'};
  width: 100%;
  overflow: ${inView ? 'visible' : 'hidden'};
  ${inView ? `border-top: 1px solid ${theme.colors.borderDark};` : ''}
`);
const AnimatedActionBar = motion.custom(StyledActionBar);
const ActionBarContainer = styled(Box) `
  height: 0px;
  position: relative;
`;
const HEIGHT = 85;
const ActionBar = forwardRef(({ headline, description, children, bg = 'baseWhite', isSticky = false, color = theme.colors.brandBrown, descriptionColor, }, ref) => {
    const [offset, setOffset] = useState(0);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [inView, setInView] = useState(false);
    const containerRef = useRef(null);
    const actionBarRef = ref || useRef(null);
    const timeout = useRef(null);
    const hasTitleOrDescripton = headline || description;
    const calculateOffset = React.useCallback(() => {
        if (containerRef && containerRef.current) {
            setOffset(containerRef.current.offsetTop);
        }
    }, [containerRef]);
    useEffect(() => {
        const callback = function (mutationsList, observer) {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    calculateOffset();
                }
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(document.body, {
            attributes: false,
            childList: true,
            subtree: true,
        });
        return () => observer.disconnect();
    }, []);
    useLayoutEffect(() => {
        calculateOffset();
    }, []);
    useEffect(() => {
        // if it's in the main viewport on initial render, ignore scroll
        if (offset > 0 && offset < window.innerHeight) {
            setInView(true);
        }
        else {
            if (scrollProgress >= offset + HEIGHT) {
                setInView(true);
            }
            else {
                setInView(false);
            }
        }
    }, [scrollProgress, offset]);
    useEffect(() => {
        const handleScroll = () => {
            if (timeout.current) {
                window.cancelAnimationFrame(timeout.current);
            }
            timeout.current = window.requestAnimationFrame(() => {
                setScrollProgress(window.pageYOffset + window.innerHeight);
            });
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [offset, timeout]);
    return (React.createElement(ActionBarContainer, { "data-testid": "action-bar", ref: containerRef },
        React.createElement(AnimatedActionBar, { ref: actionBarRef, className: "ActionBar", width: 1, px: [2], py: [inView ? 2 : 0], bg: bg, justifyContent: "space-between", isSticky: isSticky, inView: inView, color: color, offset: offset, animate: { height: inView ? 'auto' : '0px' }, initial: { height: '0px' } },
            (headline || description) && (React.createElement(Flex, { flexDirection: "column" },
                headline && React.createElement(Heading, { size: "h7" }, headline),
                description && (React.createElement(Text, { size: "tiny", color: descriptionColor }, description)))),
            React.createElement(Flex, { justifyContent: [
                    hasTitleOrDescripton ? 'flex-end' : 'space-between',
                ], alignItems: "center", flex: "1 0 auto" }, children))));
});
export default ActionBar;
